//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AppUserType { 
	Fdot = 0, 
	Public = 1
}
export enum StatusCode { 
	New = 0, 
	Pending = 1, 
	Approved = 2
}
export enum InspectionType { 
	Accident = 1, 
	Interim = 2, 
	Other = 3, 
	Routine = 4
}
export enum ActivityList { 
	Inspector = 2, 
	WPSO = 3, 
	Inspector_Final = 4, 
	BridgeInspector_Initial = 5, 
	Load_Rating_Reviewer = 6, 
	BMSE = 7, 
	BridgeInspector_Final = 8, 
	DSFE = 9, 
	Document_Manager = 10
}
export enum QueryNodeFieldType { 
	NotSelected = 0, 
	BridgeInspectionId = 1, 
	ActivityNumber = 2, 
	BegunDate = 3, 
	InspectionType = 4, 
	ReceivedDate = 5, 
	Inspector = 6, 
	StageComplete = 7, 
	InspectionCompletionDate = 8, 
	CumulativeDays = 9, 
	FlowDays = 10, 
	RemainingDays = 11, 
	BridgeNumber = 12, 
	WordProcessor = 13
}
export enum QueryNodeOperatorType { 
	NotSelected = 0, 
	Equals = 1, 
	NotEquals = 2, 
	GreaterThan = 3, 
	LessThan = 4
}
