
    <div class="modal-header">
    <h4 class="modal-title">New Inspection</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="bridgeNumber">Bridge Number</label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="editbridgeNumber"
          name="bridgeNumber"
          [(ngModel)]="bridgeInspection.bridgeId"
          placeholder="Enter the 6 digit Bridge Number"
          aria-placeholder="Enter the 6 digit Bridge Number"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-field-validation-message
        property="BridgeId"
        [validations]="validations"
      ></app-field-validation-message>
    </div>
  </div>
  <div class="row">
    <div class="col">
    <div class="input-group">
          <!-- <input class="form-control" placeholder="dd/mm/yyyy"
                 name="d2" #c2="ngModel" [(ngModel)]="begunDate" ngbDatepicker #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div> -->
        </div>
      <div class="form-group">
        <label class="form-label" for="BeginDate">Begin Date</label>
        <div class="input-group input-group-sm">
          <input
            id="BeginDate"
            class="form-control"
            placement="top-left"
            [firstDayOfWeek]="7"
            placeholder="MM-DD-YYYY"
            placement="right"
            name="dp"
            [(ngModel)]="begunDate"
            ngbDatepicker
            #d="ngbDatepicker"
            style="max-width: 150px;"

          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              (click)="d.toggle()"
              type="button"
            >
              <span class="fas fa-calendar"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="assignedInspector">Assigned Inspector</label>
        <app-staff-picker
          id="assignedInspector"
          title="Assigned Inspector"
          [selectedStaff]="selectedStaff"
          (selected)="changeAssignment($event)"
        ></app-staff-picker>
      </div>
    </div>
  </div>
  <div class="row text-danger" role="alert" *ngIf="hasclientErrors">
    <div class="col">
      <strong>Errors:</strong>
      <div [innerHTML]="clienterrors" tabindex="0" #error></div>
    </div>
  </div>
  </div>
  <div class="modal-footer">
  <div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-sm"
    (click)="activeModal.close()"
    aria-label="Cancel"
    title="Cancel"
  >
    <span class="fas fa-ban"></span>&nbsp;Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="addNewSample()"
    aria-label="Save"
    title="Save"
  >
    <span class="fas fa-save"></span>&nbsp;Save
  </button>
</div>
  </div>

  