<div class="footer-buffer"></div>
<div class="container-fluid">
  <footer class="d-sm-block fixed-bottom fdot-footer fdot-footer-sm">
    <div class="row">
      <div class="col">
        <ul class="fdot-navbottom">
          <li>
            <a title="Contact Us" href="{{ footer.contact }}">Contact Us</a>
          </li>
          <li>
            <a
              title="Web Policies &amp; Notices"
              href="{{ footer.webPolicies }}"
              >Web Policies &amp; Notices</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <footer
    class="d-none d-md-block fixed-bottom fdot-footer {{
      isAuthenticated ? 'fdot-footer-sm' : 'fdot-footer-lg'
    }} "
  >
    <div class="row">
      <div class="col">
        <div class="d-none d-lg-block">
          <ul class="fdot-navbottom">
            <li>
              <a title="Contact Us" href="{{ footer.contact }}">Contact Us</a>
            </li>
            <li>
              <a title="Employment" href="{{ footer.employment }}"
                >Employment</a
              >
            </li>
            <li>
              <a title="MyFlorida.com" href="{{ footer.myFlorida }}"
                >MyFlorida.com</a
              >
            </li>
            <li>
              <a title="Performance" href="{{ footer.performance }}"
                >Performance</a
              >
            </li>
            <li>
              <a title="Statement of Agency" href="{{ footer.agencyStatement }}"
                >Statement of Agency</a
              >
            </li>
            <li>
              <a
                title="Web Policies &amp; Notices"
                href="{{ footer.webPolicies }}"
                >Web Policies &amp; Notices</a
              >
            </li>
          </ul>
        </div>
        <div class="d-none d-md-block d-lg-none">
          <ul class="fdot-navbottom">
            <li>
              <a title="Contact Us" href="{{ footer.contact }}">Contact Us</a>
            </li>
            <li>
              <a title="Statement of Agency" href="{{ footer.agencyStatement }}"
                >Statement of Agency</a
              >
            </li>
            <li>
              <a
                title="Web Policies &amp; Notices"
                href="{{ footer.webPolicies }}"
                >Web Policies &amp; Notices</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="fdot-SocialMediaIcons">
          <a href="{{ footer.facebook }}">
            <img
              src="{{ footer.facebookLogo }}"
              alt="Link to FDOT's Facebook page"
              title="Link to FDOT's Facebook page"
              width="32"
              height="32"
              border="0"
            />
          </a>
          <a href="{{ footer.twitter }}">
            <img
              src="{{ footer.twitterLogo }}"
              alt="Link to FDOT's Twitter page"
              title="Link to FDOT's Twitter page"
              width="32"
              height="32"
              border="0"
            />
          </a>
          <a href="{{ footer.youtube }}">
            <img
              src="{{ footer.youtubeLogo }}"
              alt="Link to FDOT's YouTube page"
              title="Link to FDOT's YouTube page"
              width="45"
              height="32"
              border="0"
            />
          </a>
          <a href="{{ footer.wordpress }}">
            <img
              src="{{ footer.wordpressLogo }}"
              alt="Link to FDOT's Wordpress page"
              title="Link to FDOT's Wordpress page"
              width="32"
              height="32"
              border="0"
            />
          </a>
          <a href="{{ footer.seeFloridaGo }}">
            <img
              src="{{ footer.seeFloridaGoLogo }}"
              alt="Link to FDOT's Multimedia page"
              title="Link to FDOT's Multimedia page"
              width="32"
              height="32"
              border="0"
            />
          </a>
          <a href="{{ footer.rss }}">
            <img
              src="{{ footer.rssLogo }}"
              alt="Link to FDOT's RSS page"
              title="Link to FDOT's RSS page"
              width="32"
              height="32"
              border="0"
            />
          </a>
          <div class="fdot-copyright">
            <p>
              &copy; 1996 - {{ currentYear }} Florida Department of
              Transportation
            </p>
          </div>
        </div>
        <div class="fdot-footertag">
          <p>Florida Department of Transportation</p>
          <h5>Innovative, Efficient and Exceptional</h5>
        </div>
      </div>
    </div>
  </footer>
</div>
