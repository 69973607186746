<div class="row">
  <div class="col-12">
    <div class="text-center">
      <img
        class="img-fluid"
        src="assets/landing.png"
        alt="D2Bridges graphic"
        title="D2Bridges graphic"
      />
    </div>
  </div>
</div>
