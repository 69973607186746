<input
  [id]="id"
  [name]="id"
  type="text"
  class="form-control form-control-sm"
  [(ngModel)]="stringDate"
  mask="00-00-0000"
  [dropSpecialCharacters]="false"
  [clearIfNotMatch]="true"
  [title]="title"
  placeholder="MM-DD-YYYY"
  (blur)="onBlur()"
  style="max-width: 150px;"
  [tabindex]="Tabindex"
/>
