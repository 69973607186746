<div class="row">
  <div class="col">
    <div class="table-responsive">
      <table class="table-bordered">
        <tbody>
          <tr>
            <td style="min-width: 75px; max-width: 75px">Field:</td>
            <td
              style="min-width: 150px; max-width: 150px"
              *ngFor="let queryNode of queryNodes; let i = index"
            >
            <span *ngIf="i === queryNodes.length - 1">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.field"
                aria-label="Field"
                title="Field"
                tabindex="{{CalculateTabIndex(i,1)}}"
                appAutoFocus
              >
              <option [value]="fieldType.NotSelected" disabled selected>Choose a Field</option>
                <option [value]="fieldType.BridgeInspectionId">
                  Bridge No
                </option>
                <option [value]="fieldType.BegunDate">Begin Date</option>
                <option [value]="fieldType.ActivityNumber">Activity</option>

                <option [value]="fieldType.InspectionType">
                  Inspection Type
                </option>
                <option [value]="fieldType.ReceivedDate">Received</option>
                <option [value]="fieldType.FlowDays">Flow Days</option>
                <option [value]="fieldType.RemainingDays">
                  Remaining Days
                </option>
                <option [value]="fieldType.CumulativeDays">
                  Cumulative Days
                </option>
                <option [value]="fieldType.Inspector">Inspector</option>
                <option [value]="fieldType.WordProcessor">Word Processor</option>
                <option [value]="fieldType.InspectionCompletionDate">
                  Inspection Completion Date
                </option>
                <option [value]="fieldType.StageComplete">
                  Workflow Status
                </option>
              </select>
            </span>

            <span *ngIf="i != queryNodes.length - 1">
              <select
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.field"
                aria-label="Field"
                title="Field"
                tabindex="{{CalculateTabIndex(i,1)}}"
                appAutoFocus
              >
              <option [value]="fieldType.NotSelected" disabled selected>Choose a Field</option>
                <option [value]="fieldType.BridgeInspectionId">
                  Bridge No
                </option>
                <option [value]="fieldType.BegunDate">Begin Date</option>
                <option [value]="fieldType.ActivityNumber">Activity</option>

                <option [value]="fieldType.InspectionType">
                  Inspection Type
                </option>
                <option [value]="fieldType.ReceivedDate">Received</option>
                <option [value]="fieldType.FlowDays">Flow Days</option>
                <option [value]="fieldType.RemainingDays">
                  Remaining Days
                </option>
                <option [value]="fieldType.CumulativeDays">
                  Cumulative Days
                </option>
                <option [value]="fieldType.Inspector">Inspector</option>
                <option [value]="fieldType.WordProcessor">Word Processor</option>
                <option [value]="fieldType.InspectionCompletionDate">
                  Inspection Completion Date
                </option>
                <option [value]="fieldType.StageComplete">
                  Workflow Status
                </option>
              </select>
            </span>
            </td>
          </tr>
          <tr>
            <td style="min-width: 75px; max-width: 75px">Relation:</td>
            <td
              style="min-width: 150px; max-width: 150px"
              *ngFor="let queryNode of queryNodes; let i = index"
            >
              <select
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.operator"
                aria-label="Operator"
                title="Operator"
                tabindex="{{CalculateTabIndex(i,2)}}"
              >
                <option [value]="operatorType.NotSelected"></option>
                <option [value]="operatorType.Equals">Equals</option>
                <option [value]="operatorType.NotEquals">Not Equals</option>
                <option
                  *ngIf="
                    queryNode.field != fieldType.ActivityNumber &&
                    queryNode.field != fieldType.InspectionType &&
                    queryNode.field != fieldType.Inspector &&
                    (isDecimalField(queryNode, true) ||
                      isDateField(queryNode, true))
                  "
                  [value]="operatorType.GreaterThan"
                >
                  Greater Than
                </option>
                <option
                  *ngIf="
                    queryNode.field != fieldType.ActivityNumber &&
                    queryNode.field != fieldType.InspectionType &&
                    queryNode.field != fieldType.Inspector &&
                    (isDecimalField(queryNode, true) ||
                      isDateField(queryNode, true))
                  "
                  [value]="operatorType.LessThan"
                >
                  Less Than
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td style="min-width: 75px; max-width: 75px">Value:</td>
            <td
              style="min-width: 150px; max-width: 150px"
              *ngFor="let queryNode of queryNodes; let i = index"
            >
              <input
                *ngIf="isTextField(queryNode)"
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.stringValue"
                placeholder="Enter the value"
                aria-placeholder="Value for "
                title="Value"
              />
              <input
                *ngIf="
                  isDecimalField(queryNode) &&
                  queryNode.field != fieldType.ActivityNumber &&
                  queryNode.field != fieldType.InspectionType
                "
                type="number"
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.decimalValue"
                placeholder="Value for  queryNode.field"
                aria-placeholder="Enter the value"
                tabindex="{{CalculateTabIndex(i,3)}}"
              />
              <select
                *ngIf="
                  isDecimalField(queryNode) &&
                  queryNode.field == fieldType.ActivityNumber
                "
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.decimalValue"
                aria-label="Value for"
                title=""
                tabindex="{{CalculateTabIndex(i,3)}}"
              >
                <option [value]=""></option>
                <option [value]="activityType.Inspector">
                  Bridge Inspector
                </option>
                <option [value]="activityType.WPSO">Word Processing</option>
                <option [value]="activityType.Inspector_Final">
                  Inspector Final Review
                </option>
                <option [value]="activityType.BridgeInspector_Initial">
                  District Bridge Inspection Administrator Initial review
                </option>
                <option [value]="activityType.Load_Rating_Reviewer">
                  Load Rating Review
                </option>
                <option [value]="activityType.BMSE">
                  Bridge Management System Engineering Review
                </option>
                <option [value]="activityType.BridgeInspector_Final">
                  District Bridge Inspection Administrator Final Review
                </option>
                <option [value]="activityType.DSFE">
                  District Structure & Facilities Engineer Review
                </option>
                <option [value]="activityType.Document_Manager">
                  Document Manager
                </option>
              </select>
              <select
                *ngIf="
                  isDecimalField(queryNode) &&
                  queryNode.field == fieldType.InspectionType
                "
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.decimalValue"
                aria-label="Value for Inspection type"
                title="Enter the value"
                tabindex="{{CalculateTabIndex(i,3)}}"
              >
                <option [value]=""></option>
                <option [value]="inspectionType.Accident">Accident</option>
                <option [value]="inspectionType.Interim">Interim</option>
                <option [value]="inspectionType.Routine">Routine</option>
                <option [value]="inspectionType.Other">Other</option>
              </select>

              <select
                *ngIf="
                  isBooleanField(queryNode) &&
                  queryNode.field == fieldType.StageComplete
                "
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.booleanValue"
                aria-label="Value for workflow status"
                title=""
                tabindex="{{CalculateTabIndex(i,3)}}"
              >
                <option [value]="null"></option>
                <option [value]="true">Complete</option>
                <option [value]="false">In Progress</option>
              </select>

              <select
                *ngIf="
                  isBooleanField(queryNode) &&
                  queryNode.field != fieldType.StageComplete
                "
                class="form-control form-control-sm"
                [(ngModel)]="queryNode.booleanValue"
                aria-label="Value for"
                title=""
                tabindex="{{CalculateTabIndex(i,3)}}"
              >
                <option [value]="null"></option>
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
              <app-date-field
                *ngIf="
                  isDateField(queryNode) &&
                  queryNode.field == fieldType.BegunDate
                "
                [(moment)]="BegunDate[i]"
                [Tabindex]="CalculateTabIndex(i,3)"
              ></app-date-field>
              <app-date-field
                *ngIf="
                  isDateField(queryNode) &&
                  queryNode.field == fieldType.ReceivedDate
                "
                [(moment)]="ReceivedDate[i]"
                [Tabindex]="CalculateTabIndex(i,3)"
              ></app-date-field>
              <app-date-field
                *ngIf="
                  isDateField(queryNode) &&
                  queryNode.field == fieldType.InspectionCompletionDate
                "
                [(moment)]="InspectionCompletionDate[i]"
                [Tabindex]="CalculateTabIndex(i,3)"   
              ></app-date-field>
            </td>
          </tr>
          <tr>
            <td style="min-width: 75px; max-width: 75px"></td>
            <td
              style="min-width: 150px; max-width: 150px; text-align: right"
              *ngFor="let queryNode of queryNodes; let i = index"
            >
              <span *ngIf="i > 0">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-danger"
                  (click)="removeQueryNode(queryNode)"
                  aria-label="Remove query node"
                  title="Remove query node"
                  tabindex="{{CalculateTabIndex(i,4)}}"
                >
                  <span class="fas fa-trash-alt"></span>
                </button>
              </span>
              <span *ngIf="i === queryNodes.length - 1">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  (click)="addQueryNode()"
                  aria-label="Add query node"
                  title="Add query node"
                  tabindex="{{CalculateTabIndex(i,5)}}"
                >
                  <span class="fas fa-plus"></span>
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<br />
<div class="row">
  <div class="col text-right">
    <button
      type="button"
      class="btn btn-sm btn-primary"
      (click)="submitQuery()"
      aria-label="Submit"
      title="Submit"
      [disabled]="
        queryNodes === undefined ||
        queryNodes === null ||
        queryNodes.length === 0 ||
        queryNodes[0].field === fieldType.NotSelected
      "
    >
      Submit&nbsp;&nbsp;<span class="fas fa-cogs"></span>
    </button>
    &nbsp;
    <button
      type="button"
      class="btn btn-sm btn-primary"
      (click)="export()"
      aria-label="export to excel"
      title="export to excel"
      [disabled]="
        queryResults === undefined ||
        queryResults === null ||
        queryResults.length === 0
      "
    >
      Export to Excel&nbsp;&nbsp;
      <span class="fas fa-file-excel"></span>
    </button>
  </div>
</div>
<br />
<div
  class="row"
  *ngIf="
    queryResults !== undefined &&
    queryResults !== null &&
    queryResults.length > 0
  "
>
  <div class="col small">Page {{ currentPage }} of {{ totalPages }}</div>
  <div class="col">
    <div class="text-right">
      <button
        class="btn btn-secondary btn-sm"
        title="previous page"
        [disabled]="currentPage == 1"
        (click)="page('previous')"
      >
        <span class="fas fa-angle-left"></span></button
      >&nbsp;&nbsp;
      <button
        class="btn btn-secondary btn-sm"
        title="next page"
        [disabled]="currentPage == totalPages"
        (click)="page('next')"
      >
        <span class="fas fa-angle-right"></span>
      </button>
    </div>
  </div>
</div>
<br />
<div class="row">
  <div class="col table-responsive">
    <table
      class="table table-hover table-sm"
      aria-label="Query Results"
      title="Query Results"
    >
      <thead class="thead-dark">
        <tr>
          <th *ngFor="let header of resultFields; let i = index">
            <button
              type="button"
              class="btn btn-sm btn-link text-white"
              (click)="sort(header, i)"
              title="Sort by {{ getColumnHeader(header) }} {{
                currentSortField === header
                  ? currentSortDirection === 'ascending'
                    ? 'descending'
                    : 'ascending'
                  : 'ascending'
              }}"
            >
              <span *ngIf="currentSortField === header">
                <span *ngIf="currentSortDirection === 'descending'">
                  <span class="fas fa-sort-alpha-up"></span>
                </span>
                <span *ngIf="currentSortDirection === 'ascending'">
                  <span class="fas fa-sort-alpha-down"></span>
                </span>
              </span>
              {{ getColumnHeader(header) }}
            </button>
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let queryResult of pageData">
        <tr>
          <td *ngFor="let item of queryResult">
            <span *ngIf="item.type === 'string' || item.type === 'number'">{{
              item.value
            }}</span>
            <span *ngIf="item.type === 'date'">{{
              item.value | date: 'MM/dd/yyyy'
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
