<!-- FDOT Header -->
<div class="fdot-brand">
  <div class="float-left fdot-logo-container">
    <a href="{{ header.root }}">
      <img
        src="{{ header.logo }}"
        width="235"
        height="104"
        border="0"
        alt="State of Florida Department of Transportation logo - link to the FDOT website"
        title="State of Florida Department of Transportation logo - link to the FDOT website"
      />
    </a>
    <div class="d-lg-none" style="height: 30px;"></div>
  </div>
  <div class="float-left d-none d-md-block">
    <div class="fdot-tagline">
      <h5>
        <a
          title="link to the FDOT website"
          alt="link to the FDOT website"
          href="{{ header.root }}"
          >Florida Department of</a
        >
      </h5>
      <h1>
        <a
          title="link to the FDOT website"
          alt="link to the FDOT website"
          href="{{ header.root }}"
          >TRANSPORTATION</a
        >
      </h1>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<div class="d-none d-lg-block">
  <ul class="fdot-nav">
    <li>
      <img
        src="{{ header.leftSlant }}"
        alt="spacer for header"
        width="101"
        height="30"
        border="0"
      />
    </li>
    <li><a title="Home page" href="{{ header.home }}">Home</a></li>
    <li><a title="About FDOT" href="{{ header.about }}">About FDOT</a></li>
    <li><a title="Contact Us" href="{{ header.contact }}">Contact Us</a></li>
    <li>
      <a title="Maps &amp; Data" href="{{ header.maps }}">Maps &amp; Data</a>
    </li>
    <li><a title="Offices" href="{{ header.offices }}">Offices</a></li>
    <li>
      <a title="Performance" href="{{ header.performance }}">Performance</a>
    </li>
    <li><a title="Projects" href="{{ header.projects }}">Projects</a></li>
  </ul>
</div>
<div class="clearfix"></div>
<div class="fdot-header-bottom"></div>
<!-- End FDOT Header -->
