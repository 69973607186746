import { Component, OnInit, OnDestroy } from '@angular/core';
import { IQueryNode, IQueryResponse } from 'src/app/model/model';
import {
  QueryNodeFieldType,
  QueryNodeOperatorType,
  InspectionType,
  ActivityList,
} from 'src/app/model/model.enums';
import { HttpService } from 'src/app/services/http/http.service';
import * as moment from 'moment';
import * as linq from 'linq';
import { ExcelExportService } from 'src/app/services/data/excel-export.service';
import { cloneDeep } from 'lodash';

export class FieldValue {
  type: 'string' | 'number' | 'date';
  value: any;
}

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
})
export class QueryComponent implements OnInit, OnDestroy {
  fieldType = QueryNodeFieldType;
  operatorType = QueryNodeOperatorType;
  inspectionType = InspectionType;
  activityType = ActivityList;
  queryNodes: Array<IQueryNode> = [];
  BegunDate: Array<moment.Moment> = [];
  ReceivedDate: Array<moment.Moment> = [];
  InspectionCompletionDate: Array<moment.Moment> = [];
  queryResults: Array<Array<FieldValue>> = [];
  pageData: Array<Array<FieldValue>> = [];
  resultFields: Array<QueryNodeFieldType> = [];
  currentPage = 0;
  totalPages = 0;
  pageSize = 20;
  currentSortField: QueryNodeFieldType;
  currentSortDirection: 'ascending' | 'descending';
  selectedFieldValue: number;
  constructor(
    private httpService: HttpService,
    private excelExportService: ExcelExportService
  ) {}

  ngOnInit(): void {
    this.addQueryNode();
  }

  ngOnDestroy(): void {}

  addQueryNode(): void {
    const queryNode: IQueryNode = {};
    queryNode.field = this.fieldType.NotSelected;
    queryNode.operator = this.operatorType.NotSelected;
    this.queryNodes.push(queryNode);
    this.BegunDate.push(null);
  }

  removeQueryNode(queryNode: IQueryNode): void {
    this.queryNodes.forEach((node, index) => {
      if (node === queryNode) {
        this.queryNodes.splice(index, 1);
        this.BegunDate.splice(index, 1);
        return;
      }
    });
  }

  isTextField(queryNode: IQueryNode): boolean {
    if (this.isOperatorNotSelected(queryNode)) {
      return false;
    }
    if (this.isField(queryNode, this.fieldType.Inspector)|| this.isField(queryNode, this.fieldType.WordProcessor)) {
      return true;
    }
    return false;
  }

  isDecimalField(
    queryNode: IQueryNode,
    operatorCheck: boolean = false
  ): boolean {
    if (!operatorCheck && this.isOperatorNotSelected(queryNode)) {
      return false;
    }
    if (
      this.isField(queryNode, this.fieldType.BridgeInspectionId) ||
      this.isField(queryNode, this.fieldType.ActivityNumber) ||
      this.isField(queryNode, this.fieldType.InspectionType) ||
      this.isField(queryNode, this.fieldType.CumulativeDays) ||
      this.isField(queryNode, this.fieldType.FlowDays) ||
      this.isField(queryNode, this.fieldType.RemainingDays)
    ) {
      return true;
    }
    return false;
  }

  isBooleanField(queryNode: IQueryNode): boolean {
    if (this.isOperatorNotSelected(queryNode)) {
      return false;
    }
    if (this.isField(queryNode, this.fieldType.StageComplete)) {
      return true;
    }
    return false;
  }

  isDateField(queryNode: IQueryNode, operatorCheck: boolean = false): boolean {
    if (!operatorCheck && this.isOperatorNotSelected(queryNode)) {
      return false;
    }

    if (
      this.isField(queryNode, this.fieldType.BegunDate) ||
      this.isField(queryNode, this.fieldType.ReceivedDate) ||
      this.isField(queryNode, this.fieldType.InspectionCompletionDate)
    ) {
      return true;
    }
    return false;
  }

  submitQuery(): void {
    this.queryResults = [];
    this.pageData = [];
    // remove field not selected nodes
    this.queryNodes.forEach((node, index) => {
      if (node.operator === this.operatorType.NotSelected) {
        node.dateValue = null;
        node.decimalValue = null;
        node.stringValue = null;
        node.booleanValue = null;
      }
      if (
        Number.parseInt(node.field.toString(), 10) ===
        this.fieldType.NotSelected
      ) {
        this.queryNodes.splice(index, 1);
      }
      if (
        Number.parseInt(node.field.toString(), 10) ===
          this.fieldType.BegunDate &&
        this.BegunDate[index] !== undefined &&
        this.BegunDate[index] !== null &&
        this.BegunDate[index].isValid()
      ) {
        node.dateValue = this.BegunDate[index].toDate();
      }
      if (
        Number.parseInt(node.field.toString(), 10) ===
          this.fieldType.ReceivedDate &&
        this.ReceivedDate[index] !== undefined &&
        this.ReceivedDate[index] !== null &&
        this.ReceivedDate[index].isValid()
      ) {
        node.dateValue = this.ReceivedDate[index].toDate();
      }
      if (
        Number.parseInt(node.field.toString(), 10) ===
          this.fieldType.InspectionCompletionDate &&
        this.InspectionCompletionDate[index] !== undefined &&
        this.InspectionCompletionDate[index] !== null &&
        this.InspectionCompletionDate[index].isValid()
      ) {
        node.dateValue = this.InspectionCompletionDate[index].toDate();
      }
    });
    this.resultFields = linq
      .from(this.queryNodes)
      .select((x) => x.field)
      .distinct()
      .toArray();
    // api call
    this.httpService.post<Array<IQueryNode>, Array<IQueryResponse>>(
      'api/Query/GetInspectionsByQuery',
      this.queryNodes,
      (result) => {
        result.forEach((queryResponse) => {
          const row: Array<FieldValue> = [];
          this.resultFields.forEach((queryNode) => {
            row.push(this.getColumnValue(queryNode, queryResponse));
          });
          this.queryResults.push(row);
        });
        console.log(this.queryResults);
        this.currentPage = 0;
        this.totalPages = Math.floor(this.queryResults.length / this.pageSize);
        if (this.queryResults.length % this.pageSize > 0) {
          this.totalPages += 1;
        }
        this.page('next');
      }
    );
  }

  export(): void {
    const row: Array<any> = [];
    this.resultFields.forEach((queryNode) => {
      row.push(this.getColumnHeader(queryNode));
    });
    const results = cloneDeep(this.queryResults);
    const exportRows: Array<any> = [];
    results.forEach((x) => {
      exportRows.push(
        linq
          .from(x)
          .select((v) =>
            v.type === 'date' ? this.transformUtcDate(v.value) : v.value
          )
          .toArray()
      );
    });
    exportRows.splice(0, 0, row);
    this.excelExportService.export(exportRows, (worksheet, completed) => {
      completed(worksheet, 'data.csv');
    });
  }

  page(direction: 'next' | 'previous'): void {
    this.currentPage += direction === 'next' ? 1 : -1;
    this.pageData = linq
      .from(this.queryResults)
      .skip((this.currentPage - 1) * this.pageSize)
      .take(this.pageSize)
      .toArray();
  }

  sort(sortField: QueryNodeFieldType, fieldIndex: number): void {
    if (sortField === this.currentSortField) {
      if (this.currentSortDirection === 'ascending') {
        this.currentSortDirection = 'descending';
      } else {
        this.currentSortDirection = 'ascending';
      }
    } else {
      this.currentSortField = sortField;
      this.currentSortDirection = 'ascending';
    }
    if (this.currentSortDirection === 'ascending') {
      this.queryResults = linq
        .from(this.queryResults)
        .orderBy((x) => (x[fieldIndex] == null ? null : x[fieldIndex].value))
        .toArray();
    } else {
      this.queryResults = linq
        .from(this.queryResults)
        .orderByDescending((x) =>
          x[fieldIndex] == null ? null : x[fieldIndex].value
        )
        .toArray();
    }
    if (this.pageSize > 0) {
      this.pageData = linq
        .from(this.queryResults)
        .skip((this.currentPage - 1) * this.pageSize)
        .take(this.pageSize)
        .toArray();
    }
  }

  getColumnHeader(queryNodeFieldType: QueryNodeFieldType): string {
    if (queryNodeFieldType === undefined || queryNodeFieldType === null) {
      return '';
    }
    switch (Number.parseInt(queryNodeFieldType.toString(), 10)) {
      case QueryNodeFieldType.BridgeInspectionId:
        return 'Bridge No';
      case QueryNodeFieldType.ActivityNumber:
        return 'Activity';
      case QueryNodeFieldType.BegunDate:
        return 'Begin Date';
      case QueryNodeFieldType.InspectionType:
        return 'Inspection Type';
      case QueryNodeFieldType.ReceivedDate:
        return 'Received';
      case QueryNodeFieldType.Inspector:
        return 'Inspector';
        case QueryNodeFieldType.WordProcessor:
          return 'Word Processor';
      case QueryNodeFieldType.StageComplete:
        return 'Workflow Status';
      case QueryNodeFieldType.InspectionCompletionDate:
        return 'Inspection Completion Date';
      case QueryNodeFieldType.CumulativeDays:
        return 'Cumulative Days';
      case QueryNodeFieldType.RemainingDays:
        return 'Remaining Days';
      case QueryNodeFieldType.FlowDays:
        return 'Flow Days';
      default:
        return '';
    }
  }

  private isField(queryNode: IQueryNode, fieldType: number): boolean {
    return Number.parseInt(queryNode.field.toString(), 10) === fieldType;
  }

  private isOperatorNotSelected(queryNode: IQueryNode): boolean {
    return (
      Number.parseInt(queryNode.operator.toString(), 10) ===
      this.operatorType.NotSelected
    );
  }

  private getColumnValue(
    queryNodeFieldType: QueryNodeFieldType,
    queryResponse: IQueryResponse
  ): FieldValue {
    switch (Number.parseInt(queryNodeFieldType.toString(), 10)) {
      case QueryNodeFieldType.BridgeInspectionId:
        return queryResponse.bridgeInspectionId == null
          ? null
          : { type: 'number', value: queryResponse.bridgeInspectionId };
      case QueryNodeFieldType.ActivityNumber:
        return queryResponse.activityNumber == null
          ? null
          : {
              type: 'number',
              value: this.getActivityType(queryResponse.activityNumber),
            };
      case QueryNodeFieldType.BegunDate:
        return { type: 'date', value: queryResponse.begunDate };
      case QueryNodeFieldType.InspectionType:
        return queryResponse.inspectionType == null
          ? null
          : {
              type: 'string',
              value: this.getInspectionType(queryResponse.inspectionType),
            };
      case QueryNodeFieldType.ReceivedDate:
        return { type: 'date', value: queryResponse.received };
      case QueryNodeFieldType.Inspector:
        return { type: 'string', value: queryResponse.inspector };
        case QueryNodeFieldType.WordProcessor:
          return { type: 'string', value: queryResponse.wordProcessor };
      case QueryNodeFieldType.StageComplete:
        return {
          type: 'string',
          value: queryResponse.stageComplete ? 'Complete' : 'In Progress ',
        };
      case QueryNodeFieldType.FlowDays:
        return { type: 'string', value: queryResponse.flowDays };
      case QueryNodeFieldType.CumulativeDays:
        return { type: 'string', value: queryResponse.cumulativeDays };
      case QueryNodeFieldType.RemainingDays:
        return { type: 'string', value: queryResponse.remainingDays };
      case QueryNodeFieldType.InspectionCompletionDate:
        return {
          type: 'date',
          value: queryResponse.inspectionCompletionDate,
        };
    }
  }

  private getInspectionType(id: number): string {
    return id == this.inspectionType.Accident
      ? 'Accident'
      : id == this.inspectionType.Interim
      ? 'Interim'
      : id == this.inspectionType.Other
      ? 'Other'
      : id == this.inspectionType.Routine
      ? 'Routine'
      : '';
  }

  private getActivityType(id: number): string {
    return id == this.activityType.Inspector
      ? 'Bridge Inspector'
      : id == this.activityType.WPSO
      ? 'Word Processing'
      : id == this.activityType.Inspector_Final
      ? 'Inspector Final Review'
      : id == this.activityType.BridgeInspector_Initial
      ? 'District Bridge Inspection Administrator Initial review'
      : id == this.activityType.Load_Rating_Reviewer
      ? 'Load Rating Review'
      : id == this.activityType.BMSE
      ? 'Bridge Management System Engineering Review'
      : id == this.activityType.BridgeInspector_Final
      ? 'District Bridge Inspection Administrator Final Review'
      : id == this.activityType.DSFE
      ? 'District Structure & Facilities Engineer Review'
      : id == this.activityType.Document_Manager
      ? 'Document Manager'
      : '';
  }

  private transformUtcDate(utcDate: Date): Date {
    const m = moment(utcDate);
    if (!m.isUTC()) {
      const utcOffset = m.utcOffset() * -1;
      return m.add(utcOffset, 'minutes').toDate();
    } else {
      return null;
    }
  }

  private CalculateTabIndex(Index: number, Pos: number): number {
     let Tabindex:number=0;
     if(Index===0){
      Tabindex = Pos;
     }
     else if(Index>0){
      Tabindex = (Index*5)+Pos
     }
    return Tabindex;
  }

  // private selectChangeHandler(event: any): void {
  //   this.selectedFieldValue = event.target.value;
  //   // checking received date is selected or not
  //   if (this.selectedFieldValue == this.fieldType.FlowDays) {
  //     let findReceived = this.queryNodes.some((node, index) => {
  //       if (node.field == this.fieldType.ReceivedDate) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     // show pop up and unselect flow date value
  //     if (!findReceived) {
  //       console.log('ReceivedDate not selected');
  //     }
  //   }

  //   // checking Begun date and findActivityNumber is selected or not
  //   if (this.selectedFieldValue == this.fieldType.RemainingDays) {
  //     let findBegunDate = this.queryNodes.some((node, index) => {
  //       if (node.field == this.fieldType.BegunDate) {
  //         return true;
  //       }
  //       return false;
  //     });

  //     let findActivityNumber = this.queryNodes.some((node, index) => {
  //       if (node.field == this.fieldType.ActivityNumber) {
  //         return true;
  //       }
  //       return false;
  //     });

  //     if (!findBegunDate && !findActivityNumber) {
  //       console.log('BegunDate and ActivityNumber not selected');
  //     }
  //   }
  //   // checking bridge number is not selected or not
  //   if (this.selectedFieldValue == this.fieldType.CumulativeDays) {
  //     let findBridgeNo = this.queryNodes.some((node, index) => {
  //       if (node.field == this.fieldType.BridgeInspectionId) {
  //         return true;
  //       }
  //       return false;
  //     });

  //     if (!findBridgeNo) {
  //       console.log('Bridge number is not selected');
  //     }
  //   }
  // }
}
