<div
  *ngIf="errors && errors.length > 0"
  class="text-danger"
  style="margin-bottom: 8px;"
  role="alert"
>
  <div class="float-left" style="width: 60px;"><strong> Error: </strong></div>
  <div class="float-left">
    <div *ngFor="let error of errors">{{ error }}</div>
  </div>
  <div class="clearfix"></div>
</div>
