import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  TemplateRef,
  Injectable,
} from '@angular/core';
import * as moment from 'moment';
import {
  NgbModal,
  ModalDismissReasons,
  NgbDateAdapter,
  NgbDateStruct,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http/http.service';
import { BridgeStoreService } from 'src/app/features/Bridge-Store.Service';
import {
  DataNavigation,
  DataNavigationService,
  FilterEvent,
} from '../../services/data/data-navigation.service';
import * as linq from 'linq';
import {
  IActivities,
  IActivityResponse,
  IBridgeInspection,
  IBridgeSearchRecords,
  IBridgeRecord,
  IReportDetail,
} from '../../model/model';
import { InspectionType, ActivityList } from '../../model/model.enums';
import { SecurityService } from '../../services/security/security.service';
import { TableComponent } from '../../../app/components/common/table/table.component';
import { BridgeRecordModalComponent } from '../bridge-record-modal/bridge-record-modal.component';
import { ToastService } from 'src/app/services/environment/toast.service';
import { PdfExportService } from 'src/app/services/data/pdf-export.service';
import { createOfflineCompileUrlResolver } from '@angular/compiler';

import {
  Select2OptionData,
  Options,
} from '../../../app/components/common/select2/select2.interface';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';
  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year
      : null;
  }
}

/* This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';
  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year
      : '';
  }
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  pullData: number;
  closeResult: string;
  data: DataNavigation<IActivities>;
  userId = '';
  checkUserId = '';
  isAdmin = false;
  isBridgeInspector = false;
  isWordProcessor = false;
  isLoadRatingReviewer = false;
  isBridgeManagementSystemEngineer = false;
  isDistrictBridgeInspectorAdmin = false;
  isDistrictStructuresFacilitiesEngineer = false;
  isDocumentManager = false;
  activityResponse2: number;
  activityResponse3: number;
  activityResponse4: number;
  activityResponse5: number;
  activityResponse6: number;
  activityResponse7: number;
  activityResponse8: number;
  activityResponse9: number;
  activityResponse10: number;
  filters: Array<FilterEvent> = [];
  bridgeSearch: number;
  bridgeSearchResults: IBridgeSearchRecords[] = [];
  inspectionType = InspectionType;
  @Input() observableFilter: string;
  bridgeQueryErrors = '';
  @ViewChild('BridgeInspectionRemovedToast', { static: true })
  bridgeInspectionRemovedToast: TemplateRef<any>;
  removebridgeInspection: number;
  bridgeAverageReportErrors = '';
  activityType = ActivityList;
  SelectedActivities: Array<ActivityList> = [];

  selectedItems = [];

  optionalHeader = [];
  requiredField: boolean = false;
  errorlist: string[];

  dropdownList: Array<Select2OptionData>;

  value: string;
  options: Options;

  constructor(
    private modalService: NgbModal,
    private bridgeStoreService: BridgeStoreService,
    private httpService: HttpService,
    private toastService: ToastService,
    private dataNavigationService: DataNavigationService,
    private securityService: SecurityService,
    private pdfExport: PdfExportService
  ) {}

  ngOnInit() {
    this.dropdownList = [
      { id: this.activityType.Inspector.toString(), text: 'Bridge Inspector' },

      { id: this.activityType.WPSO.toString(), text: 'Word Processing' },

      {
        id: this.activityType.Inspector_Final.toString(),
        text: 'Inspector Final Review',
      },

      {
        id: this.activityType.BridgeInspector_Initial.toString(),
        text: 'District Bridge Inspection Administrator Initial review',
      },

      {
        id: this.activityType.Load_Rating_Reviewer.toString(),
        text: 'Load Rating Review',
      },

      {
        id: this.activityType.BMSE.toString(),
        text: 'Bridge Management Systems Engineering Review',
      },

      {
        id: this.activityType.BridgeInspector_Final.toString(),
        text: 'District Bridge Inspection Administrator Final Review',
      },

      {
        id: this.activityType.DSFE.toString(),
        text: 'District Structures & Facilities Engineer Review',
      },

      {
        id: this.activityType.Document_Manager.toString(),
        text: 'Document Manager',
      },
    ];

    this.options = {
      multiple: true,
      closeOnSelect: false,
      width: '100%',
    };

    this.securityService.safeSubscribe(
      this,
      (token) => {
        if (token == null) {
          this.userId = '';
        } else {
          const userIdParts = token.userId.split('\\');
          if (userIdParts.length === 2) {
            this.userId = userIdParts[1];
          } else {
            this.userId = '';
          }
          this.isBridgeInspector =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any((x) => x === 'BridgeInspector');
          this.isWordProcessor =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any((x) => x === 'WordProcessor');
          this.isLoadRatingReviewer =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any((x) => x === 'LoadRatingReviewer');
          this.isBridgeManagementSystemEngineer =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq
              .from(token.roles)
              .any((x) => x === 'BridgeManagementSystemEngineer');
          this.isDistrictBridgeInspectorAdmin =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any((x) => x === 'DistrictBridgeInspector');
          this.isDistrictStructuresFacilitiesEngineer =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq
              .from(token.roles)
              .any((x) => x === 'DistrictStructuresFacilitiesEngineer');
          this.isDocumentManager =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any((x) => x === 'DocumentManager');
          this.isAdmin =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any((x) => x === 'Admin');
        }
      },
      () => {
        this.securityService.getToken();
      }
    );
    this.getActivityResponse();
  }

  getActivityResponse() {
    this.httpService.get<IActivityResponse[]>(
      'api/BridgeInspection/GetInspectionsCountByActivity',
      (result) => {
        this.activityResponse2 =
          result.find((x) => x.activity == 2) != undefined
            ? result.find((x) => x.activity == 2).count
            : 0;
        this.activityResponse3 =
          result.find((x) => x.activity == 3) != undefined
            ? result.find((x) => x.activity == 3).count
            : 0;
        this.activityResponse4 =
          result.find((x) => x.activity == 4) != undefined
            ? result.find((x) => x.activity == 4).count
            : 0;
        this.activityResponse5 =
          result.find((x) => x.activity == 5) != undefined
            ? result.find((x) => x.activity == 5).count
            : 0;
        this.activityResponse6 =
          result.find((x) => x.activity == 6) != undefined
            ? result.find((x) => x.activity == 6).count
            : 0;
        this.activityResponse7 =
          result.find((x) => x.activity == 7) != undefined
            ? result.find((x) => x.activity == 7).count
            : 0;
        this.activityResponse8 =
          result.find((x) => x.activity == 8) != undefined
            ? result.find((x) => x.activity == 8).count
            : 0;
        this.activityResponse9 =
          result.find((x) => x.activity == 9) != undefined
            ? result.find((x) => x.activity == 9).count
            : 0;
        this.activityResponse10 =
          result.find((x) => x.activity == 10) != undefined
            ? result.find((x) => x.activity == 10).count
            : 0;
      },
      (errors) => {
        console.log('errors in populate lookups');
      }
    );
  }

  getTableData(itemClicked: number) {
    const modalRef = this.modalService.open(TableComponent, {
      backdrop: 'static',
      size: 'xl',
    });

    modalRef.result.finally(() => {
      this.getActivityResponse();
    });
    modalRef.componentInstance.pullDataFor = itemClicked;

    //modalRef.close(() => {this.getActivityResponse();})

    // this.modalService.open(TableComponent, {size: 'xl',
    // centered: true , scrollable: true }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  getSelectedBridgeInfo(itemClicked: number) {
    if (itemClicked) {
      this.httpService.get<IBridgeRecord>(
        'api/BridgeInspection/GetBridgeRecordQuery?inspectionId=' + itemClicked,
        (result) => {
          var img = new Image();
          img.src = './assets/pencil-bridge.jpg';
          img.onload = () => {
            const modalRef = this.modalService.open(
              BridgeRecordModalComponent,
              {
                backdrop: 'static',
                size: 'lg',
              }
            );
            modalRef.componentInstance.pullDataFor = itemClicked;
            modalRef.componentInstance.bridgeInspectionRecord = result;
            let completedDate =
              result.filingDate != null
                ? moment(result.filingDate).format('MM-DD-YYYY')
                : 'N/A';
            // tslint:disable-next-line:max-line-length
            modalRef.componentInstance.modalHeader =
              'History of Bridge # ' +
              result.bridgeId +
              ' Inspection Completed: ' +
              completedDate +
              '';
          };
        },
        (errors) => {
          console.log(errors);
        }
      );
    }
  }

  searchBridges(bridgeId: number) {
    this.bridgeSearchResults = [];
    this.bridgeQueryErrors = '';
    if (
      bridgeId != undefined &&
      parseInt(bridgeId.toString()) &&
      bridgeId.toString().length === 6
    ) {
      this.httpService.get<IBridgeInspection[]>(
        'api/BridgeInspection/GetBridgeSearchRecords?bridgeId=' + bridgeId,
        (result) => {
          if (result.length > 0)
            result.forEach((element) => {
              this.bridgeSearchResults.push(element);
            });
          else {
            this.bridgeQueryErrors =
              '0 Inspections found for Bridge # ' + bridgeId;
          }
          // var img = new Image();
          // img.src = '../../../assets/pencil-bridge.jpg';
        },
        (errors) => {
          this.bridgeQueryErrors = errors.list();
        }
      );
    } else {
      this.bridgeQueryErrors =
        'Bridge Number cannot be null & should be a 6 digit number';
    }
  }

  getAverageReviewTimeReport() {
    this.bridgeAverageReportErrors = '';
    this.errorlist = [];

    if (
      this.fromDate != undefined &&
      this.toDate != undefined &&
      moment(this.fromDate) != null &&
      moment(this.toDate) != null &&
      moment(this.toDate) >= moment(this.fromDate)
    ) {
      var qryStr =
        '?fromDate=' +
        moment(this.fromDate).toDate().toJSON() +
        '&toDate=' +
        moment(this.toDate).toDate().toJSON();
      if (this.SelectedActivities.length > 0)
        qryStr += '&Activities=' + this.SelectedActivities;

      this.httpService.get<IReportDetail>(
        'api/BridgeInspection/GetBridgeAverageReport' + qryStr,
        (result) => {
          let report = result.rawData;
          let circularDatesAbove60 = report.filter(
            (x) => x.circulationDays > 60
          );
          let sumOfDaysWithInspector = 0;
          let sumOfdaysWithWordProcessor = 0;
          let sumOfdaysWithInspectorAfterWordProcessor = 0;
          let sumOfdaysWithDistrictBridgeAdminInitial = 0;
          let sumOfdaysWithLoadRatingReview = 0;
          let sumOfdaysWithBridgeManagementSystem = 0;
          let sumOfdaysWithDistrictBridgeAdminFinal = 0;
          let sumOfdaysWithStructuresAndFacilitiesEngineer = 0;
          let sumOfdaysWithDocumentsManager = 0;
          report.forEach((element) => {
            sumOfDaysWithInspector =
              sumOfDaysWithInspector + element.daysWithInspector;
            sumOfdaysWithWordProcessor =
              sumOfdaysWithWordProcessor + element.daysWithWordProcessor;
            sumOfdaysWithInspectorAfterWordProcessor =
              sumOfdaysWithInspectorAfterWordProcessor +
              element.daysWithInspectorAfterWordProcessor;
            sumOfdaysWithDistrictBridgeAdminInitial =
              sumOfdaysWithDistrictBridgeAdminInitial +
              element.daysWithDistrictBridgeAdminInitial;
            sumOfdaysWithLoadRatingReview =
              sumOfdaysWithLoadRatingReview + element.daysWithLoadRatingReview;
            sumOfdaysWithBridgeManagementSystem =
              sumOfdaysWithBridgeManagementSystem +
              element.daysWithBridgeManagementSystem;
            sumOfdaysWithDistrictBridgeAdminFinal =
              sumOfdaysWithDistrictBridgeAdminFinal +
              element.daysWithDistrictBridgeAdminFinal;
            sumOfdaysWithStructuresAndFacilitiesEngineer =
              sumOfdaysWithStructuresAndFacilitiesEngineer +
              element.daysWithStructuresAndFacilitiesEngineer;
            sumOfdaysWithDocumentsManager =
              sumOfdaysWithDocumentsManager + element.daysWithDocumentsManager;
          });

          const averageCalculation: any = {
            numberOfInspectionsOverRun: circularDatesAbove60.length,
            totalNumberOfProjects: report.length,
            percentageOverTime: Math.round(
              (circularDatesAbove60.length / report.length) * 100
            ),
            AverageFlowDaysStep2: Math.round(
              sumOfDaysWithInspector / report.length
            ),
            AverageFlowDaysStep3: Math.round(
              sumOfdaysWithWordProcessor / report.length
            ),
            AverageFlowDaysStep4: Math.round(
              sumOfdaysWithInspectorAfterWordProcessor / report.length
            ),
            AverageFlowDaysStep5: Math.round(
              sumOfdaysWithDistrictBridgeAdminInitial / report.length
            ),
            AverageFlowDaysStep6: Math.round(
              sumOfdaysWithLoadRatingReview / report.length
            ),
            AverageFlowDaysStep7: Math.round(
              sumOfdaysWithBridgeManagementSystem / report.length
            ),
            AverageFlowDaysStep8: Math.round(
              sumOfdaysWithDistrictBridgeAdminFinal / report.length
            ),
            AverageFlowDaysStep9: Math.round(
              sumOfdaysWithStructuresAndFacilitiesEngineer / report.length
            ),
            AverageFlowDaysStep10: Math.round(
              sumOfdaysWithDocumentsManager / report.length
            ),
            fromDate: moment(this.fromDate).toDate().toJSON(),
            toDate: moment(this.toDate).toDate().toJSON(),
          };
          const exportData: any = linq
            .from(report)
            .select<any>((x) => {
              return {
                bridgeId: x.bridgeId,
                completionDate: moment(x.filingDate).format('L'),
                daysWithInspector: Math.round(x.daysWithInspector),
                daysWithWordProcessor: Math.round(x.daysWithWordProcessor),
                daysWithInspectorAfterWordProcessor: Math.round(
                  x.daysWithInspectorAfterWordProcessor
                ),
                daysWithDistrictBridgeAdminInitial: Math.round(
                  x.daysWithDistrictBridgeAdminInitial
                ),
                daysWithLoadRatingReview: Math.round(
                  x.daysWithLoadRatingReview
                ),
                daysWithBridgeManagementSystem: Math.round(
                  x.daysWithBridgeManagementSystem
                ),
                daysWithDistrictBridgeAdminFinal: Math.round(
                  x.daysWithDistrictBridgeAdminFinal
                ),
                daysWithStructuresAndFacilitiesEngineer: Math.round(
                  x.daysWithStructuresAndFacilitiesEngineer
                ),
                daysWithDocumentsManager: Math.round(
                  x.daysWithDocumentsManager
                ),
                daysOverTimeAllowed: Math.round(x.daysOverTimeAllowed),
                circulationDays: Math.round(x.circulationDays),
              };
            })
            .toArray();

          this.pdfExport.exportAverageReviewReport(
            exportData,
            averageCalculation,
            'InspectionReport',
            'Inspection Report Review Time Query',
            200,
            false,
            this.userId,
            result.headerDetails
          );
        },
        (errors) => {
          this.bridgeAverageReportErrors = errors.list();
        }
      );
    } else {
      this.bridgeAverageReportErrors =
        'From Date,To Date and Activity is required, To Date should be greater than from date.';
    }
  }

  removeBridgeInspection(
    deleteModal: any,
    bridgeSearchResults: IBridgeSearchRecords
  ): void {
    this.removebridgeInspection = bridgeSearchResults.bridgeId;
    this.modalService
      .open(deleteModal, { ariaLabelledBy: 'deleteSampleModalLabel' })
      .result.then(
        () => {
          this.bridgeStoreService.remove(bridgeSearchResults, () => {
            this.clearCheckUserId();
            this.toastService.show(this.bridgeInspectionRemovedToast, {
              classname: 'bg-danger text-light',
              delay: 5000,
            });
            this.bridgeSearchResults.filter(
              (x) => x.bridgeInspectionId !== bridgeSearchResults.bridgeId
            );
          });
        },
        () => {
          this.clearCheckUserId();
        }
      );
  }

  clearCheckUserId(): void {
    this.checkUserId = '';
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy(): void {}
}
