import { Injectable } from '@angular/core';
import {
  HttpService,
  ModelStateValidations
} from '../../app/services/http/http.service';
import * as linq from 'linq';
import { IActivities, IStringResponse, IBridgeInspection, IBridgeSearchRecords } from '../model/model';
import { Store } from 'src/app/services/store/store.service';

@Injectable()
export class BridgeStoreService extends Store<IActivities[]> {
  constructor(private httpService: HttpService) {
    super('BridgeStoreService', new Array<IActivities>());
  }

  load(
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ) {
    this.httpService.get<IActivities[]>(
      'api/BridgeInspection/GetInspectionsCountByActivity',
      result => {
        this.setState([...result]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }

  loadwithParam(
    filter: number, 
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ): void {
    this.httpService.get<IActivities[]>(
      'api/BridgeInspection/GetInspectionsByActivity?activityId=' + filter,
      result => {
      
        this.setState([...result]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }
  add(
    activity: IActivities,
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ): void {
    this.httpService.post<IActivities, IActivities>(
      'api/BridgeInspection/AddOrUpdateInspection',
      activity,
      result => {
        this.setState([...this.getState(), result]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }

  addNewBridgeInspection(
    bridgeInspection: IBridgeInspection,
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ) {    
    this.httpService.post<IBridgeInspection, IBridgeInspection>(
      'api/BridgeInspection/AddBridgeInspection',
      bridgeInspection,
      result => {    
        this.addNewActivity2(result);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }

  addNewActivity2(  
    bridgeInspection: IBridgeInspection,
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ): void {   
    var newBridge : IBridgeInspection ={};
    newBridge.id = bridgeInspection.id;
    newBridge.bridgeId = bridgeInspection.bridgeId;
    newBridge.begunDate = bridgeInspection.begunDate;
    bridgeInspection.lastUpdatedAppUser = null; 
    bridgeInspection.inspectorIdAppUser = null;
    bridgeInspection.lastUpdatedAppUserId = null;
    this.httpService.post<IBridgeInspection,IActivities>(
      'api/BridgeInspection/AddActivity',
      newBridge,      
      result => {
        this.setState([...this.getState(), result]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }

  updateActivity(
    activity: IActivities,
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ): void {
    activity.lastUpdatedAppUser = null;
    this.httpService.post<IActivities, IActivities>(
      'api/BridgeInspection/UpdateActivity',
      activity,
      result => {      
        this.setState([
          ...linq
            .from(this.getState())
            .where(x => x.id !== result.id)
            .toArray()]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }

  returnActivity( activity: IActivities,
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ): void {
    activity.lastUpdatedAppUser = null;
    this.httpService.post<IActivities, IActivities>(
      'api/BridgeInspection/ReturnActivity',
      activity,
      result => {
        this.setState([
          ...linq
            .from(this.getState())
            .where(x => x.id !== activity.id)
            .toArray()]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }


  update(
    activity: IActivities,
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ): void {
    this.httpService.post<IActivities, IActivities>(
      'api/BridgeInspection/AddOrUpdateInspection',
      activity,
      result => {
        this.setState([
          ...linq
            .from(this.getState())
            .where(x => x.id !== activity.id)
            .toArray(),
          result
        ]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }

  remove(
    bridgeInspection: IBridgeSearchRecords,
    callback?: () => void,
    errorCallback?: (errors: ModelStateValidations) => void
  ): void {
    this.httpService.post<number, IStringResponse>(
      'api/BridgeInspection/RemoveInspection',
      bridgeInspection.bridgeInspectionId,
      result => {            
        this.setState([
          ...linq
            .from(this.getState())
            .where(x => x.bridgeInspectionId !== bridgeInspection.bridgeInspectionId)
            .toArray()
        ]);
        if (callback) {
          callback();
        }
      },
      errors => {
        if (errorCallback) {
          errorCallback(errors);
        }
      }
    );
  }
}
