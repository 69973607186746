import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  HttpService
} from '../../services/http/http.service';
import {IBridgeRecord} from '../../model/model';
import {PdfExportService} from '../../services/data/pdf-export.service';

@Component({
  selector: 'app-bridge-record-modal',
  templateUrl: './bridge-record-modal.component.html',
  styleUrls: ['./bridge-record-modal.component.scss']
})
export class BridgeRecordModalComponent implements OnInit {

  
  @Input() observableFilter: string;
  pullDataFor: number;
  modalHeader: string;
  bridgeInspectionRecord : IBridgeRecord = {};
  fdotimage : HTMLImageElement;
  fdotDataURI = "";
  @ViewChild('pdfDocPage1', { static: true }) pdfDocPage1: ElementRef;
  @ViewChild('pdfDocHeader', { static: true }) pdfDocHeader: ElementRef;
  constructor(public modalService: NgbActiveModal,
    private httpService : HttpService,
    private http : HttpClient,
    private pdfExport : PdfExportService) {}



  ngOnInit() {


  }





  exporttoPdf(print: boolean): void {
    var data = this.pdfDocPage1.nativeElement;
    document.documentElement.scrollTop = 0; 
    this.pdfExport.pdfExport(data, this.bridgeInspectionRecord.bridgeId.toString(), this.modalHeader, 200, print);
    //doc.save(this.bridgeInspectionRecord.bridgeId.toString());
    }

  }





