
    <div  #pdfDocHeader class="modal-header">
    <h4> {{modalHeader }}</h4>
    
      <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Cancel"
      title="Cancel"
      (click)="modalService.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div #pdfDocPage1 class="modal-body"> 
        <div class="row">
            <div class="col-4"> <img src="../../../assets/pencil-bridge.jpg" class="img-thumbnail" style="width: 150px;" /></div>
            
            <div class="col" style="text-align: justify;margin-top: 30px;"><h5>Bridge Report Query</h5></div>
        </div>
        &nbsp;
        <div class="row">
            <div class="col-sm-8"><label for="bridgeNumber">Bridge Number</label></div>
            
            <div class="col"><input id="bridgeNumber" class="form-label" [ngModel]="bridgeInspectionRecord.bridgeId" style="border:none;"></div>
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="inspectionBeginDate">Inspection Begin Date</label></div>
            <div class="col"><input id="inspectionBeginDate" class="form-label"  [ngModel]="bridgeInspectionRecord.inspectionBegunDate | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="inspectionDueDate">Inspection due date (CIDR Inspection Date)</label></div>
            <div class="col"><input id="inspectionDueDate" class="form-label"  [ngModel]="bridgeInspectionRecord.inspectionDueDate | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="inspectionCompletedDate">Inspection Completed Date (field work completed)</label></div>
            <div class="col"><input id="inspectionCompletedDate" class="form-label"  [ngModel]="bridgeInspectionRecord.fieldInspectionCompleted | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>

        <div class="row">
            <div class="col-sm-8"><label for="whyInspection">Inspection Type</label></div>
            <div class="col">
                {{ bridgeInspectionRecord.inspectionType == 1 ? 'Accident' : 
                bridgeInspectionRecord.inspectionType == 2 ? 'Interim' : 
                bridgeInspectionRecord.inspectionType == 3 ? 'Other' :  
                bridgeInspectionRecord.inspectionType == 4 ? 'Routine': ''}}
            </div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="inspectorResponsible">Inspector </label></div>
            <div class="col"><input id="inspectorResponsible" class="form-label"  [ngModel]="bridgeInspectionRecord.inspectorResponsible" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="ToWordProcessor">Route to Word Processing Systems Operator</label></div>
            <div class="col"><input id="ToWordProcessor" class="form-label"  [ngModel]="bridgeInspectionRecord.toWordProcessor | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="ToBridgeInspector">Route to Bridge Inspector for Review</label></div>
            <div class="col"><input id="ToBridgeInspector" class="form-label"  [ngModel]="bridgeInspectionRecord.toBridgeInspector | date:'MM-dd-yyyy'"  style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="WordProcessor">Word Processing Operator</label></div>
            <div class="col"><input id="WordProcessor" class="form-label"  [ngModel]="bridgeInspectionRecord.wordProcessor" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="ToDBIA">Route to District Bridge Inspection Administrator (DBIA)</label></div>
            <div class="col"><input id="ToDBIA" class="form-label"  [ngModel]="bridgeInspectionRecord.toDistrictBridgeInspectionAdmin | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="InitialReviewCompletedDate">Initial Review Complete (DBIA) </label></div>
            <div class="col"><input id="InitialReviewCompletedDate" class="form-label"  [ngModel]="bridgeInspectionRecord.initialReviewCompletedDate | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="LoadRatingCompletedDate">Load Rating Review Complete</label></div>
            <div class="col"><input id="LoadRatingCompletedDate" class="form-label"  [ngModel]="bridgeInspectionRecord.loadRatingCompletedDate | date:'MM-dd-yyyy'"  style="border:none;"></div>      
        </div>
        <div class="row">
         
            <div class="col-sm-8"><label for="ToDBIA">Bridge Management Systems Engineer to {{ bridgeInspectionRecord.bridgeManagementEngineerTransferTo == 1 ? 'WPO':
                bridgeInspectionRecord.bridgeManagementEngineerTransferTo == 2 ? 'DBIA' : 'Documents Manager'}}  </label></div>
            <div class="col"><input id="ToDBIA" class="form-label"  [ngModel]="bridgeInspectionRecord.bridgeManagementEngineerTransferDate | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="FinalReviewCompletedDate">DBIA Final Review </label></div>
            <div class="col"><input id="FinalReviewCompletedDate" class="form-label"  [ngModel]="bridgeInspectionRecord.finalReview | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>

         <div class="row">
            <div class="col-sm-8"><label for="FacilitiesEngineerReview">Date Reviewed by DSFE</label></div>
            <div class="col"><input id="FacilitiesEngineerReview" class="form-label"  [ngModel]="bridgeInspectionRecord.facilitiesEngineerReview | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
        <div class="row">
            <div class="col-sm-8"><label for="FilingDate">Date Report was Filed by Documents Manager</label></div>
            <div class="col"><input id="FilingDate" class="form-label"  [ngModel]="bridgeInspectionRecord.filingDate | date:'MM-dd-yyyy'" style="border:none;"></div>      
        </div>
     
       
       
        <!-- <div class="row">
            <div class="col-sm-8"><label for="dateOfBirth">Date Reviewed by FARC</label></div>
            <div class="col"><input id="dateOfBirth" class="form-label"  ></div>      
        </div> -->
        <div class="row">
            <div class="col-sm-8"><label for="WorkOrdersRequired">Work Order Required?</label></div>
            <div class="col"><input id="WorkOrdersRequired" class="form-label"  [ngModel]="bridgeInspectionRecord.workOrdersRequired == false ? 'No' : 'Yes'" style="border:none;"></div>      
        </div>

      
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="exporttoPdf(true)" aria-label="Print the Bridge Inspection Record"
      title="Print the Bridge Inspection Record"><span class="fas fa-print"></span>Print</button>
      <button type="button" class="btn btn-primary" (click)="exporttoPdf(false)" aria-label="Export the Bridge Inspection Record to PDF"
      title="Export the Bridge Inspection Record to PDF"><span class="fas fa-file-pdf"></span>Export</button>
    </div>
    
  
  
  
  

  
  
  