<button
  class="btn btn-link sr-only sr-only-focusable"
  title="Skip to main content"
  aria-label="Skip to main content"
  (click)="skipNavigation()"
>
  Skip to main content
</button>
<app-header></app-header>
<nav id="navigationMenu" class="navbar navbar-expand-lg navbar-light bg-light">
  <a
    class="navbar-brand"
    aria-label="Go to D2Bridges home"
    title="Go to D2Bridges home"
    routerLink="/"
  >
    <span class="d-none d-md-block">
      <img
        style="width: 30px"
        src="assets/logo.png"
        title="D2Bridges logo"
      />&nbsp;D2 Bridge Routing Log</span
    >
    <span class="d-sm-block d-md-none">
      <img
        style="width: 30px"
        src="assets/logo.png"
        title="D2Bridges logo"
      />&nbsp;D2 Bridge Routing Log</span
    >
  </a>
  <button
    class="navbar-toggler"
    type="button"
    aria-controls="navbarContent"
    [attr.aria-expanded]="!collapsed"
    aria-label="Toggle navigation"
    (click)="collapsed = !collapsed"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse"
    id="navbarContent"
    [ngbCollapse]="collapsed"
  >
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" *ngIf="isAuthenticated">
        <a
          class="dropdown-item"
          aria-label="Bridge Routing Log Dashboard"
          title="Bridge Routing Log Dashboard"
          (click)="$event.preventDefault()"
          routerLink="/dashboard"
          routerLinkActive="active"
          >Dashboard</a
        >
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a
          class="dropdown-item"
          aria-label="query"
          title="query"
          (click)="$event.preventDefault()"
          routerLink="/query"
          routerLinkActive="active"
          >Query</a
        >
      </li>
      <!-- <li *ngIf="isAdmin || isB2CUser" class="nav-item" ngbDropdown>
        <a
          class="nav-link"
          style="cursor: pointer"
          ngbDropdownToggle
          id="adminDropdown"
          role="button"
        >
          <span class="fas fa-cogs"></span>&nbsp;Administration
        </a>
        <div
          ngbDropdownMenu
          aria-labelledby="adminDropdown"
          class="dropdown-menu dropdown-menu-right"
        >
           ** SAMPLE START
          <a
            class="dropdown-item"
            aria-label="Sample administration"
            title="Sample administration"
            (click)="$event.preventDefault()"
            routerLink="/administration/sample"
            >Samples</a
          >

          </div>
      </li> -->
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item" *ngIf="!isAuthenticated">
        <a
          class="nav-link"
          href="/security/adlogin"
          aria-label="Network Login"
          title="Network Login"
        >
          <span class="fas fa-sign-in-alt"></span>&nbsp;Network Login
        </a>
      </li>
      <li class="nav-item" *ngIf="!isAuthenticated && allowB2C">
        <a
          class="nav-link"
          href="/security/b2clogin"
          aria-label="Consumer Login"
          title="Consumer Login"
        >
          <span class="fas fa-sign-in-alt"></span>&nbsp;Consumer Login
        </a>
      </li>
      <li
        class="nav-item"
        *ngIf="isAuthenticated && allowB2C && isB2CUser && isB2CAuthenticated"
      >
        <a
          class="nav-link"
          href="/security/b2cprofile"
          aria-label="User Profile"
          title="User Profile"
        >
          <span class="fas fa-user-edit"></span>&nbsp;User Profile
        </a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a
          class="nav-link"
          href="/security/logout"
          aria-label="Logout"
          title="Logout"
        >
          <span class="fas fa-sign-out-alt"></span>&nbsp;Logout {{ userName }}
          {{ userId == '' ? '' : '(' + userId + ')' }}
        </a>
      </li>
    </ul>
    <form
      class="form-inline my-2 my-lg-0"
      *ngIf="isAuthenticated && canImpersonate && !isB2CUser"
    >
      <select
        id="roleImpersonation"
        class="form-control"
        [ngModel]="role"
        (ngModelChange)="changeRole($event)"
        aria-label="Impersonation"
        title="Impersonation"
        [ngModelOptions]="{ standalone: true }"
        disabled
      >
        <option value="Admin">Admin</option>
        <option value="BridgeInspector">BridgeInspector</option>
        <option value="WordProcessor">WordProcessor</option>
        <option value="LoadRatingReviewer">LoadRatingReviewer</option>
        <option value="BridgeManagementSystemEngineer">
          BridgeManagementSystemEngineer
        </option>
        <option value="DistrictBridgeInspector">DistrictBridgeInspector</option>
        <option value="DistrictStructuresFacilitiesEngineer">
          DistrictStructuresFacilitiesEngineer
        </option>
        <option value="DocumentManager">DocumentManager</option>
      </select>
    </form>
  </div>
</nav>
<a [routerLink]="" #skipnav></a>
