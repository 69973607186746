import { Injectable } from '@angular/core';
//import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
var jsPDF = require('jspdf');
require('jspdf-autotable');

@Injectable()
export class PdfExportService {
  constructor() {}
  fdotDataURI: string;
  pdfExport(
    data: any,
    fileName: string,
    header: string,
    height: any,
    print: boolean
  ) {
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      // var imgWidth = 190;
      // var pageHeight = 295;
      // var imgHeight = canvas.height * imgWidth / canvas.width;
      // var heightLeft = imgHeight;
      var doc = new jsPDF('a4');
      doc.setFont('times');
      doc.setDrawColor(66, 139, 202);
      var img = new Image();
      img.src = './assets/fdot.jpg';
      img.onload = () => {
        this.fdotDataURI = this.getBase64Image(img);
        doc.addImage(this.fdotDataURI, 'JPEG', 10, 12, 20, 15);
        doc.rect(5, 10, 190, 30);
        doc.text(45, 18, 'FLORIDA DEPARTMENT OF TRANSPORTATION');
        doc.text(95, 25, 'District 2');
        doc.text(65, 35, header);
        doc.setFontSize(12);
        doc.addImage(canvas.toDataURL('image/png'), 'PNG', 20, 42, 160, height);
        if (print) {
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');
        } else {
          doc.save(fileName + '.pdf');
        }
      };
    });
  }

  getBase64Image(img) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext('2d');
    ctx.fillStyle = 'rgb(200,0,0)';
    ctx.fillRect(40, 60, 20, 20);
    ctx.drawImage(img, 0, 0);
    var dataURL = '';
    dataURL = canvas.toDataURL('image/jpg');

    return dataURL;
    // dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  public exportAverageReviewReport(
    exportData: any,
    averageCalculation: any,
    fileName: string,
    header: string,
    height: any,
    print: boolean,
    userId: string,
    optionalHeader: any
  ) {
    var doc = new jsPDF('A4');
    var columns = [
      { title: 'Bridge #', dataKey: 'bridgeId' },
      { title: 'Insp Com Date', dataKey: 'completionDate' },

      { title: 'Circulation Days 60', dataKey: 'circulationDays' },
      { title: 'Days Over Time Allowed', dataKey: 'daysOverTimeAllowed' },
    ];

    columns.splice(2, 0, ...optionalHeader);

    var img = new Image();
    img.src = './assets/fdot.jpg';
    img.onload = () => {
      this.fdotDataURI = this.getBase64Image(img);
      //     doc.addImage(this.fdotDataURI,'JPEG',10,12,20,15);
      // doc.rect(5, 10, 190,30);
      // doc.text(45,18,"FLORIDA DEPARTMENT OF TRANSPORTATION")
      // doc.text(95,25,"District 2")
      // doc.text(50,35, header);
      doc.setFontSize(12);
      doc.autoTable(columns, exportData, {
        theme: 'grid',
        pageBreak: 'auto',
        showHead: 'everyPage',
        margin: { horizontal: 5, vertical: 45 },
        bodyStyles: { valign: 'top' },
        styles: {
          cellPadding: 0.5,
          fontSize: 8,
          overflow: 'linebreak',
          cellWidth: 'auto',
          halign: 'center',
        },
        columnStyles: {
          text: { cellWidth: 'wrap' },
          Comments: { cellWidth: 'wrap' },
        },
        willDrawCell: (exportData) => {
          for (i = 0; i < exportData.table.body.length; i++) {
            // if(exportData.table.body[i].raw.active == 'No')
            // exportData.table.body[i].cells.active.styles.fillColor = [204,255,204];
            if (exportData.table.body[i].raw.circulationDays > 60) {
              exportData.table.body[i].cells.circulationDays.styles.fillColor =
                [250, 218, 94];
              exportData.table.body[i].cells.circulationDays.styles.fontStyle =
                'bold';
            }

            optionalHeader.forEach((hdr) => {
              if (exportData.table.body[i].raw[hdr.dataKey] > hdr.gracePeriod) {
                exportData.table.body[i].cells[hdr.dataKey].styles.fontStyle =
                  'bold';
              }
            });
          }
        },
      });

      // doc.text(15,45,'Insp Comp     Word Proc     Rpt Gen Comp     SBIE       LR      BMSE      SBIE       DSFE       Doc Mgr');
      // doc.text(15,50,'    8                    8                    8                  8                     3             9                 8              5              5');
      // doc.text(15,55,'---------             ---------           ------------          ----       --      ----      ----       ----       -------');

      // doc.text(10, 285, moment().format('L'));
      // doc.text(180,285,userId);
      //doc.text(180,285,'Watermark');
      //doc.text(10, 5, 'Another Way of exporting to PDF without customization.');
      //doc.autoTable({html: '#my-table',theme: 'grid',pageBreak: 'auto',showHead: 'everyPage'});
      doc.addPage();
      doc.autoTable({
        head: [
          [
            'Insp Comp 8',
            'Word Proc 8',
            'Rpt Gen Comp 8',
            'SBIE 8',
            'LR 3',
            'BMSE 9',
            'SBIE 8',
            'DSFE 5',
            'Doc Mgr 5',
          ],
        ],
        body: [
          [
            Math.round(averageCalculation.AverageFlowDaysStep2),
            Math.round(averageCalculation.AverageFlowDaysStep3),
            Math.round(averageCalculation.AverageFlowDaysStep4),
            Math.round(averageCalculation.AverageFlowDaysStep5),
            Math.round(averageCalculation.AverageFlowDaysStep6),
            Math.round(averageCalculation.AverageFlowDaysStep7),
            Math.round(averageCalculation.AverageFlowDaysStep8),
            Math.round(averageCalculation.AverageFlowDaysStep9),
            Math.round(averageCalculation.AverageFlowDaysStep10),
          ],
        ],
        theme: 'grid',
        pageBreak: 'auto',
        showHead: 'everyPage',
        margin: { horizontal: 5, vertical: 45 },
        bodyStyles: { valign: 'top' },
        styles: {
          cellPadding: 0.5,
          fontSize: 8,
          overflow: 'linebreak',
          cellWidth: 'auto',
          halign: 'center',
        },

        columnStyles: {
          text: { cellWidth: 'wrap' },
          Comments: { cellWidth: 'wrap' },
        },
      });
      var pageCount = doc.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(12);
        // doc.addPage();
        doc.addImage(this.fdotDataURI, 'JPEG', 10, 12, 20, 15);
        doc.rect(5, 10, 190, 30);
        doc.text(50, 18, 'FLORIDA DEPARTMENT OF TRANSPORTATION');
        doc.text(95, 25, 'District 2');
        if (i != pageCount) doc.text(65, 35, header);
        else doc.text(60, 35, 'Averages for All Reports in Review Time Query');
        doc.setFontSize(7);
        doc.text(
          10,
          260,
          '* Time taken once field work is complete to forward to word processor'
        );
        doc.text(
          10,
          265,
          'Reports Over Time:  ' +
            averageCalculation.numberOfInspectionsOverRun +
            ' Total Reports: ' +
            averageCalculation.totalNumberOfProjects
        );
        doc.text(
          10,
          270,
          'Percent of Reports Over Time: ' +
            averageCalculation.percentageOverTime +
            '%'
        );
        doc.text(
          10,
          275,
          'Range of Query: Between ' +
            moment(averageCalculation.fromDate).format('L') +
            ' and ' +
            moment(averageCalculation.toDate).format('L')
        );
        doc.text(10, 280, 'Report created on : ' + moment().toDate());
        doc.text(180, 280, moment().format('L'));
        doc.text(180, 285, userId);
        // doc.text(150, 5, heading);
        //doc.text(10, 5, header);
        doc.text(
          260,
          205,
          'Page ' + doc.internal.getCurrentPageInfo().pageNumber.toString()
        );
      }
      doc.save(header + '.pdf');
    };
  }
}
