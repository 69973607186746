<ngx-spinner
  bdOpacity="0.9"
  bdColor="rgba(51,51,51,0.59)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
  <p style="color: white">Please wait...</p>
</ngx-spinner>
<app-toasts-container
  aria-live="polite"
  aria-atomic="true"
></app-toasts-container>
<app-nav-menu></app-nav-menu>
<span class="d-none d-md-block">
  <div
    *ngIf="showWarning"
    id="environmentWarningLarge"
    class="environment-warning-content"
    role="alert"
  >
    WARNING: THIS IS NOT PRODUCTION! DO NOT DO PRODUCTION WORK IN THIS
    APPLICATION!!!
  </div>
</span>
<span class="d-sm-block d-md-none">
  <div
    *ngIf="showWarning"
    id="environmentWarningSmall"
    class="environment-warning-content"
    role="alert"
  >
    WARNING: THIS IS NOT PRODUCTION!
  </div>
</span>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div id="contentBuffer" class="content-buffer"></div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer></app-footer>
