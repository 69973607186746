<button
  type="button"
  class="btn btn-sm btn-link text-white {{ textClass }}"
  (click)="invokeSort()"
  [attr.aria-label]="'Sort by ' + label"
  title="Sort by {{ label }} {{
    sortData.currentSortField === field
      ? sortData.currentSortDirection === 'ascending'
        ? 'descending'
        : 'ascending'
      : 'ascending'
  }}"
>
  <span *ngIf="sortData.currentSortField === field">
    <span *ngIf="sortData.currentSortDirection === 'descending'">
      <span class="fas fa-sort-alpha-up"></span>
    </span>
    <span *ngIf="sortData.currentSortDirection === 'ascending'">
      <span class="fas fa-sort-alpha-down"></span>
    </span>
  </span>
  {{ label }}
</button>
