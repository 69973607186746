import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  OnChanges,
  ElementRef,
  Injectable
} from '@angular/core';
import {
  DataNavigation,
  DataNavigationService,
  FilterEvent
} from '../../../services/data/data-navigation.service';
import { IActivities, IStaff, IBridgeInspection } from '../../../model/model';
import { InspectionType } from '../../../model/model.enums';
import { SecurityService } from '../../../services/security/security.service';
import { StaffPickerComponent } from 'src/app/components/common/staff-picker/staff-picker.component';
import * as linq from 'linq';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { NgbModal, NgbActiveModal, NgbDateParserFormatter, NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { StaffService } from 'src/app/services/data/staff.service';
import { ToastService } from 'src/app/services/environment/toast.service';
import { BridgeStoreService } from 'src/app/features/Bridge-Store.Service';
import { ModelStateValidations, HttpService } from 'src/app/services/http/http.service';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter1 extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';
  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {

      let date = value.split(this.DELIMITER);
      return {
        month : parseInt(date[0], 10),
         day : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ?  date.month +  this.DELIMITER + date.day + this.DELIMITER + date.year : null;
  }
}

 /* This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter1 extends NgbDateParserFormatter {

  readonly DELIMITER = '-';
  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month : parseInt(date[0], 10),
        day : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.month + this.DELIMITER  + date.day  + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter1},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter1}
  ]
})

export class TableComponent implements OnInit, OnChanges, OnDestroy {
  userId;
  checkUserId = '';
  bridgeActivity: IActivities = {};
  @Input() data: DataNavigation<IActivities>;
  filters: Array<FilterEvent> = [];
  isAdmin = false;
  isBridgeSelected = false;
  isCollapsed = true;
  @Input() observableFilter: string;
  pullDataFor: number;
  todaysDate = moment(new Date()).format('mm/dd/yyyy');
  selectedStaff: IStaff;
  dueDate: moment.Moment;
  receivedDate ='';
  fieldInspectionCompleted: moment.Moment;
  toWordProcessor: moment.Moment;
  toBridgeInspector ='';
  toDistrictBridgeInspectionAdmin: moment.Moment;
  initialReviewCompletedDate: moment.Moment;
  waterInspectionDate: moment.Moment;
  loadRatingPendingDate: moment.Moment;
  loadRatingCompletedDate: moment.Moment;
  bridgeManagementEngineerTransferDate ='';
  finalReview: moment.Moment;
  facilitiesEngineerReview: moment.Moment;
  filingDate: moment.Moment;
  inspection: IActivities = {};
  inspectionType = InspectionType;
  bridgeInspection: IBridgeInspection = {};
  hasErrors = false;
  errors = '';
  validations: ModelStateValidations;
  returned = false;
  @ViewChild('error', { static: false }) error: ElementRef;
  header = '';





  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private securityService: SecurityService,
    private bridgeStoreService: BridgeStoreService,
    private dataNavigationService: DataNavigationService,
    private staffService: StaffService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.header = this.pullDataFor == 2 ? 'Bridge Inspector' :
                  this.pullDataFor == 3 ? 'Word Processing' :
                  this.pullDataFor == 4 ? 'Inspector Final Review' :
                  this.pullDataFor == 5 ? 'District Bridge Inspection Administrator Initial Review' :
                  this.pullDataFor == 6 ? 'Load Rating Review' :
                  this.pullDataFor == 7 ? 'Bridge Management Systems Engineering Review' :
                  this.pullDataFor == 8 ? 'District Bridge Inspection Administrator Final Review' :
                  this.pullDataFor == 9 ? 'District Structures & Facilities Engineer Review' : 'Documents Manager'
    this.securityService.safeSubscribe(
      this,
      token => {
        if (token == null) {
          this.userId = '';
        } else {
          const userIdParts = token.userId.split('\\');
          if (userIdParts.length === 2) {
            this.userId = userIdParts[1];
          } else {
            this.userId = '';
          }
          this.isAdmin =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'Admin');
        }
      },
      () => {
        this.securityService.getToken();
      }
    );
    if (this.observableFilter == null || this.observableFilter.trim() === '') {
      this.bridgeStoreService.safeSubscribe(
        this,
        samples => {
          this.dataChanged(samples);
        },
        () => {
          this.bridgeStoreService.loadwithParam(this.pullDataFor);
        }
      );
    } else {
      this.bridgeStoreService.safeSubscribeMap(
        this,
        samples => {
          return linq
            .from(samples)
            .where(x => x.bridgeInspection.bridgeId.toString() == this.observableFilter)
            .toArray();
        },
        samples => {
          this.dataChanged(samples);
        },
        () => {
          this.bridgeStoreService.loadwithParam(this.pullDataFor);
        }
      );
    }
  }

  dataChanged(samples: IActivities[]): void {
    if (this.data == null) {
      this.data = this.dataNavigationService.init(samples, 'begunDate', 10);
    } else {
      const resetPaging = this.data.sourceData.length > samples.length;
      this.data.sourceData = samples;
      this.dataNavigationService.filter(this.data, this.filters, resetPaging);
    }
    if (this.observableFilter == null || this.observableFilter.trim() === '') {
      this.bridgeStoreService.replayState(state => {
        this.formatReplayState(state);
      });
    } else {
      this.bridgeStoreService.replayStateMap(
        s => {
          return linq
            .from(s)
            .toArray();
        },
        state => {
          this.formatReplayState(state);
        }
      );
    }
  }

  formatReplayState(state: IActivities[]): void {
    if (state == null) {
      return;
    }
    for (let i = 0; i < state.length; i++) {
      console.log(
        `id:${state[i].id}, isActive:${state[i].begunDate}, name:${state[i].bridgeInspection}}`
      );
    }
  }

  ngOnDestroy(): void { }

  clearCheckUserId(): void {
    this.checkUserId = '';
  }
  changeAssignment(staff: IStaff) {
    if (staff == null) {
      this.bridgeInspection.inspectorId = null;
    } else {
      this.staffService.saveFdotAppUser(staff, fdotAppUser => {
        this.bridgeInspection.inspectorId = fdotAppUser.id;
      });
    }
  }
  sort(field: string): void {

    var filterData = [];
    this.data.sourceData.forEach(e => {
      filterData.push(e);
    });
    filterData.forEach((e,i) => {

        filterData[i].flowDays = moment().diff(filterData[i].begunDate, 'days');
        filterData[i].remainingDays = this.calculateRemainingDays(e);
        filterData[i].cummilativeDays = moment().diff(filterData[i].bridgeInspection.begunDate, 'days');
        filterData[i].bridgeInspection.begunDate = moment(filterData[i].bridgeInspection.begunDate).format('MM-DD-YYYY');
        filterData[i].begunDate = moment(filterData[i].begunDate).format('MM-DD-YYYY');

    });
    this.data.sortedFilteredData = filterData;
    this.dataNavigationService.sort(this.data, field);
  }

    filter(event: FilterEvent): void {
    console.log(event);
     this.filters = linq
      .from(this.filters)
      .where(x => x.field !== event.field)
      .toArray();
    this.filters.push(event);
    var filterData = [];
    this.data.sourceData.forEach(e => {
      filterData.push(e);
    });
    filterData.forEach((e,i) => {


      filterData[i].flowDays = moment().diff(filterData[i].begunDate, 'days');
      filterData[i].remainingDays = this.calculateRemainingDays(e);
      filterData[i].bridgeInspection.begunDate = moment(filterData[i].bridgeInspection.begunDate).format('MM-DD-YYYY');
      filterData[i].begunDate = moment(filterData[i].begunDate).format('MM-DD-YYYY');
      filterData[i].cummilativeDays = moment().diff(filterData[i].bridgeInspection.begunDate, 'days');

    });
    this.data.sourceData = filterData;
    this.dataNavigationService.filter(this.data, this.filters, true);
  }

  page(direction: 'next' | 'previous'): void {
    this.dataNavigationService.page(this.data, direction);
  }

  bridgeSelected(inspection: IActivities): void {
    this.inspection = inspection;
    this.dueDate = moment(inspection.dueDate);
    //this.receivedDate =
    console.log(moment(inspection.begunDate).format('MM-DD-YYYY'));
    this.receivedDate = moment(inspection.begunDate).format('MM-DD-YYYY');
    this.fieldInspectionCompleted = moment(inspection.fieldInspectionCompleted);
    this.toWordProcessor = moment(inspection.toWordProcessor);
    this.waterInspectionDate = inspection.underWaterInspectionRequired == true ?
      moment(inspection.underWaterInspectionDate) : null;
    this.toBridgeInspector = moment(inspection.toBridgeInspector).format('MM-DD-YYYY');
    this.toDistrictBridgeInspectionAdmin = moment(inspection.toDistrictBridgeInspectionAdmin);
    this.initialReviewCompletedDate = moment(inspection.initialReviewCompletedDate);
    this.loadRatingPendingDate = moment(inspection.loadRatingPendingDate);
    this.loadRatingCompletedDate = moment(inspection.loadRatingCompletedDate);
    this.bridgeManagementEngineerTransferDate = moment(inspection.bridgeManagementEngineerTransferDate).format('MM-DD-YYYY');
    this.finalReview = moment(inspection.finalReview);
    this.facilitiesEngineerReview = moment(inspection.facilitiesEngineerReview);
    this.filingDate = moment(inspection.filingDate);
    this.isBridgeSelected = true;
    this.isCollapsed = false;
    this.returned = inspection.returned;

  }

  calculatecumilative(bridge: IActivities): number {
    return moment().diff(bridge.fieldInspectionCompleted, 'days');
  }

  calculateFlow(bridge: IActivities): number{

    return moment().diff(bridge.begunDate, 'days');
  }
  calculateRemainingDays(bridge: IActivities): number {
    const remainingDays = bridge.activityNumber === 2 ? moment(bridge.begunDate).add(8,'days').diff(moment(new Date()), 'days') :
    bridge.activityNumber === 3 ? moment(bridge.begunDate).add(8,'days').diff(moment(new Date()), 'days') :
    bridge.activityNumber === 4 ? moment(bridge.begunDate).add(8,'days').diff(moment(new Date()), 'days') :
    bridge.activityNumber === 5 ? moment(bridge.begunDate).add(8,'days').diff(moment(new Date()), 'days') :
    bridge.activityNumber === 6 ? moment(bridge.begunDate).add(5,'days').diff(moment(new Date()), 'days') :
    bridge.activityNumber === 7 ? moment(bridge.begunDate).add(12,'days').diff(moment(new Date()), 'days') :
    bridge.activityNumber === 8 ? moment(bridge.begunDate).add(6,'days').diff(moment(new Date()), 'days') :
    bridge.activityNumber === 9 ? moment(bridge.begunDate).add(6,'days').diff(moment(new Date()), 'days') :
    moment(bridge.begunDate).add(5,'days').diff(moment(new Date()), 'days');
    return remainingDays;
  }
  ngOnChanges(): void {
    alert(this.inspection.underWaterInspectionRequired);
    if (this.isBridgeSelected == true) {
      this.bridgeSelected(this.inspection);
    }
  }

  setBridgeInspection(inspection?: IBridgeInspection): void {
    this.staffService.getByRacf(this.userId, x => {
      let staff: IStaff = {
        district: x.district,
        racfId: x.racfId,
        emailAddress: x.emailAddress,
        firstName: x.firstName,
        lastName: x.lastName,
        id: x.id
      };
      this.staffService.saveFdotAppUser(staff, fdotAppUser => {
        this.bridgeInspection =
          inspection == null
            ? {
              bridgeId: null,
              begunDate: null,
              inspectorId: fdotAppUser.id,
              inspectorIdAppUser: {
                srsId: fdotAppUser.srsId,
                district: fdotAppUser.district,
                racfId: fdotAppUser.racfId
              }
            }
            : cloneDeep(inspection);

        const modalRef = this.modalService.open(NgbdModal2Content, {
          backdrop: 'static'
        });
        modalRef.componentInstance.bridgeInspection = this.bridgeInspection;
        modalRef.componentInstance.staffPickerComponent.clearInput();
        if (
          this.bridgeInspection.inspectorIdAppUser !== undefined &&
          this.bridgeInspection.inspectorIdAppUser !== null
        ) {
          this.staffService.get(
            this.bridgeInspection.inspectorIdAppUser.srsId,
            staff => {
              modalRef.componentInstance.selectedStaff = staff;
            }
          );
        }
        if (this.bridgeInspection.begunDate == null) {
          modalRef.componentInstance.begunDate = null;
        } else {
          modalRef.componentInstance.begunDate = moment(this.bridgeInspection.begunDate);
        }
      });



    });

  }

  closeModalPopup(): void {

    this.activeModal.close();

  }


  saveActivity(): void {
    this.setDate(this.inspection);
    this.bridgeActivity = {
      id: this.inspection.id,
      activityNumber: this.inspection.activityNumber,
      bridgeInspectionId: this.inspection.bridgeInspectionId,
      dueDate: this.inspection.dueDate,
      fieldInspectionCompleted: this.inspection.fieldInspectionCompleted,
      toWordProcessor: this.inspection.toWordProcessor,
      bridgeComparisonProfile: this.inspection.bridgeComparisonProfile,
      bridgePhotos: this.inspection.bridgePhotos,
      workOrdersRequired: this.inspection.workOrdersRequired,
      underWaterInspectionRequired: this.inspection.underWaterInspectionRequired,
      underWaterInspectionDate: this.inspection.underWaterInspectionDate,
      inspectionType: this.inspection.inspectionType,
      begunDate: this.inspection.begunDate,
      comments: this.inspection.comments,
      toBridgeInspector: this.inspection.toBridgeInspector,
      toDistrictBridgeInspectionAdmin: this.inspection.toDistrictBridgeInspectionAdmin,
      loadRatingPendingDate: this.inspection.loadRatingPendingDate,
      loadRatingCompletedDate: this.inspection.loadRatingCompletedDate,
      bridgeManagementEngineerTransferTo: this.inspection.bridgeManagementEngineerTransferTo,
      bridgeManagementEngineerTransferDate: this.inspection.bridgeManagementEngineerTransferDate,
      finalReview: this.inspection.finalReview,
      facilitiesEngineerReview: this.inspection.facilitiesEngineerReview,
      filingDate: this.inspection.filingDate,
      initialReviewCompletedDate: this.inspection.initialReviewCompletedDate,
      returned: this.returned
    };

    if(this.inspection.begunDate != null){
    this.bridgeStoreService.updateActivity(
      this.inspection,
      () => {
        this.clearErrors();

        this.isBridgeSelected = false;
        // const index: number = this.data.pageData.indexOf(this.inspection);
        // this.data.pageData.splice(index, 1);
        //this.bridgeStoreService.loadwithParam(this.pullDataFor);
        this.toastService.show('Bridge Inspection updated!', {
          classname: 'bg-success text-light',
          delay: 5000
        });
      },
      errors => {
        this.hasErrors = true;
        this.errors = errors.list();
        this.validations = errors;
        this.error.nativeElement.focus();
      }
    );
    }else {
      this.hasErrors = true;
      this.errors ="<li> Received Date Cannot Be Null </li>";

        this.error.nativeElement.focus();

    }

  }


  returnActivity(): void {
    this.setDate(this.inspection);
    this.bridgeActivity = {
      id: this.inspection.id,
      activityNumber: this.inspection.activityNumber,
      bridgeInspectionId: this.inspection.bridgeInspectionId,
      dueDate: this.inspection.dueDate,
      fieldInspectionCompleted: this.inspection.fieldInspectionCompleted,
      toWordProcessor: this.inspection.toWordProcessor,
      bridgeComparisonProfile: this.inspection.bridgeComparisonProfile,
      bridgePhotos: this.inspection.bridgePhotos,
      workOrdersRequired: this.inspection.workOrdersRequired,
      underWaterInspectionRequired: this.inspection.underWaterInspectionRequired,
      underWaterInspectionDate: this.inspection.underWaterInspectionDate,
      inspectionType: this.inspection.inspectionType,
      begunDate: this.inspection.begunDate,
      comments: this.inspection.comments,
      toBridgeInspector: this.inspection.toBridgeInspector,
      toDistrictBridgeInspectionAdmin: this.inspection.toDistrictBridgeInspectionAdmin,
      loadRatingPendingDate: this.inspection.loadRatingPendingDate,
      loadRatingCompletedDate: this.inspection.loadRatingCompletedDate,
      bridgeManagementEngineerTransferTo: this.inspection.bridgeManagementEngineerTransferTo,
      bridgeManagementEngineerTransferDate: this.inspection.bridgeManagementEngineerTransferDate,
      finalReview: this.inspection.finalReview,
      facilitiesEngineerReview: this.inspection.facilitiesEngineerReview,
      filingDate: this.inspection.filingDate,
      initialReviewCompletedDate: this.inspection.initialReviewCompletedDate,
      returned: this.returned
    };


    this.bridgeStoreService.returnActivity(
      this.inspection,
      () => {
        this.clearErrors();
        this.isBridgeSelected = false;
        // const index: number = this.data.pageData.indexOf(this.inspection);
        // this.data.pageData.splice(index, 1);
        //this.bridgeStoreService.loadwithParam(this.pullDataFor);
        this.toastService.show('Bridge Inspection updated!', {
          classname: 'bg-success text-light',
          delay: 5000
        });
      },
      errors => {
        this.hasErrors = true;
        this.errors = errors.list();
        this.validations = errors;
      }
    );



  }


  clearErrors(): void {
    this.hasErrors = false;
    this.errors = '';

  }


  private setDate(inspection: IActivities): void {
    if (
      moment(this.dueDate) !== undefined &&
      moment(this.dueDate) !== null &&
      moment(this.dueDate).isValid()
    ) {
      inspection.dueDate = moment(this.dueDate).toDate();
    } else {
      inspection.dueDate = null;
    }
    if (
      moment(this.receivedDate) !== undefined &&
      moment(this.receivedDate) !== null &&
      moment(this.receivedDate).isValid()
    ) {
      inspection.begunDate = moment(this.receivedDate).toDate();
    } else {
      inspection.begunDate = null;
    }

    if (
      moment(this.fieldInspectionCompleted) !== undefined &&
      moment(this.fieldInspectionCompleted) !== null &&
      moment(this.fieldInspectionCompleted).isValid()
    ) {
      inspection.fieldInspectionCompleted = moment(this.fieldInspectionCompleted).toDate();
    } else {
      inspection.fieldInspectionCompleted = null;
    }

    if (
      moment(this.toWordProcessor) !== undefined &&
      moment(this.toWordProcessor) !== null &&
      moment(this.toWordProcessor).isValid()
    ) {
      inspection.toWordProcessor = moment(this.toWordProcessor).toDate();
    } else {
      inspection.toWordProcessor = null;
    }

    if (
      moment(this.waterInspectionDate) !== undefined &&
      moment(this.waterInspectionDate) !== null &&
      moment(this.waterInspectionDate).isValid()
    ) {
      inspection.underWaterInspectionDate = moment(this.waterInspectionDate).toDate();
    } else {
      inspection.underWaterInspectionDate = null;
    }

    if (
      moment(this.toBridgeInspector) !== undefined &&
      moment(this.toBridgeInspector) !== null &&
      moment(this.toBridgeInspector).isValid()
    ) {
      inspection.toBridgeInspector = moment(this.toBridgeInspector).toDate();
    } else {
      inspection.toBridgeInspector = null;
    }

    if (
      moment(this.toDistrictBridgeInspectionAdmin) !== undefined &&
      moment(this.toDistrictBridgeInspectionAdmin) !== null &&
      moment(this.toDistrictBridgeInspectionAdmin).isValid()
    ) {
      inspection.toDistrictBridgeInspectionAdmin = moment(this.toDistrictBridgeInspectionAdmin).toDate();
    } else {
      inspection.toDistrictBridgeInspectionAdmin = null;
    }
    if (
      moment(this.initialReviewCompletedDate) !== undefined &&
      moment(this.initialReviewCompletedDate) !== null &&
      moment(this.initialReviewCompletedDate).isValid()
    ) {
      inspection.initialReviewCompletedDate = moment(this.initialReviewCompletedDate).toDate();
    } else {
      inspection.initialReviewCompletedDate = null;
    }

    if (
      moment(this.loadRatingPendingDate) !== undefined &&
      moment(this.loadRatingPendingDate) !== null &&
      moment(this.loadRatingPendingDate).isValid()
    ) {
      inspection.loadRatingPendingDate = moment(this.loadRatingPendingDate).toDate();
    } else {
      inspection.loadRatingPendingDate = null;
    }

    if (
      moment(this.loadRatingCompletedDate) !== undefined &&
      moment(this.loadRatingCompletedDate) !== null &&
      moment(this.loadRatingCompletedDate).isValid()
    ) {
      inspection.loadRatingCompletedDate = moment(this.loadRatingCompletedDate).toDate();
    } else {
      inspection.loadRatingCompletedDate = null;
    }

    if (
      moment(this.bridgeManagementEngineerTransferDate) !== undefined &&
      moment(this.bridgeManagementEngineerTransferDate) !== null &&
      moment(this.bridgeManagementEngineerTransferDate).isValid()
    ) {
      inspection.bridgeManagementEngineerTransferDate = moment(this.bridgeManagementEngineerTransferDate).toDate();
    } else {
      inspection.bridgeManagementEngineerTransferDate = null;
    }

    if (
      moment(this.finalReview) !== undefined &&
      moment(this.finalReview) !== null &&
      moment(this.finalReview).isValid()
    ) {
      inspection.finalReview = moment(this.finalReview).toDate();
    } else {
      inspection.finalReview = null;
    }

    if (
      moment(this.facilitiesEngineerReview) !== undefined &&
      moment(this.facilitiesEngineerReview) !== null &&
      moment(this.facilitiesEngineerReview).isValid()
    ) {
      inspection.facilitiesEngineerReview = moment(this.facilitiesEngineerReview).toDate();
    } else {
      inspection.facilitiesEngineerReview = null;
    }

    if (
      moment(this.filingDate) !== undefined &&
      moment(this.filingDate) !== null &&
      moment(this.filingDate).isValid()
    ) {
      inspection.filingDate = moment(this.filingDate).toDate();
    } else {
      inspection.filingDate = null;
    }
  }

}


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';
  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month : parseInt(date[0], 10),
         day : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ?  date.month +  this.DELIMITER + date.day + this.DELIMITER + date.year : null;
  }
}

 /* This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';
  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month : parseInt(date[0], 10),
        day : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.month + this.DELIMITER  + date.day  + this.DELIMITER + date.year : '';
  }
}

@Component({
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
  template: `
    <div class="modal-header">
    <h4 class="modal-title">New Inspection</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="bridgeNumber">Bridge Number</label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="editbridgeNumber"
          name="bridgeNumber"
          [(ngModel)]="bridgeInspection.bridgeId"
          placeholder="Enter the 6 digit Bridge Number"
          aria-placeholder="Enter the 6 digit Bridge Number"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-field-validation-message
        property="BridgeId"
        [validations]="validations"
      ></app-field-validation-message>
    </div>
  </div>
  <div class="row">
    <div class="col">
    <div class="input-group">
          <!-- <input class="form-control" placeholder="dd/mm/yyyy"
                 name="d2" #c2="ngModel" [(ngModel)]="begunDate" ngbDatepicker #d2="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div> -->
        </div>
      <div class="form-group">
        <label class="form-label" for="BeginDate">Begin Date</label>
        <div class="input-group input-group-sm">
          <input
            id="BeginDate"
            class="form-control"
            placement="top-left"
            [firstDayOfWeek]="7"
            placeholder="MM-DD-YYYY"
            placement="right"
            name="dp"
            [(ngModel)]="begunDate"
            ngbDatepicker
            #d="ngbDatepicker"
            style="max-width: 150px;"

          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              (click)="d.toggle()"
              type="button"
            >
              <span class="fas fa-calendar"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="assignedInspector">Assigned Inspector</label>
        <app-staff-picker
          id="assignedInspector"
          title="Assigned Inspector"
          [selectedStaff]="selectedStaff"
          (selected)="changeAssignment($event)"
        ></app-staff-picker>
      </div>
    </div>
  </div>
  <div class="row text-danger" role="alert" *ngIf="hasclientErrors">
    <div class="col">
      <strong>Errors:</strong>
      <div [innerHTML]="clienterrors" tabindex="0" #error></div>
    </div>
  </div>
  </div>
  <div class="modal-footer">
  <div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary btn-sm"
    (click)="activeModal.close()"
    aria-label="Cancel"
    title="Cancel"
  >
    <span class="fas fa-ban"></span>&nbsp;Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="addNewSample()"
    aria-label="Save"
    title="Save"
  >
    <span class="fas fa-save"></span>&nbsp;Save
  </button>
</div>
  </div>

  `
})


export class NgbdModal2Content {
  @ViewChild(StaffPickerComponent, { static: true })
  staffPickerComponent: StaffPickerComponent;

  bridgeInspection: IBridgeInspection;
  begunDate : moment.Moment;
  selectedStaff: IStaff;
  hasErrors = false;
  errors = '';
  clienterrors = '';
  hasclientErrors = false;
  validations: ModelStateValidations;
  @ViewChild('error', { static: false }) error: ElementRef;

  constructor(public activeModal: NgbActiveModal,
    private staffService: StaffService,
    private bridgeStoreService: BridgeStoreService,
    private toastService: ToastService,
    private httpService: HttpService
  ) { }

  changeAssignment(staff: IStaff) {
    if (staff == null) {
      this.bridgeInspection.inspectorId = null;
    } else {
      this.staffService.saveFdotAppUser(staff, fdotAppUser => {
        this.bridgeInspection.inspectorId = fdotAppUser.id;
      });
    }
  }


  addNewSample(callback: void): void {
    this.hasclientErrors = false;
    this.clienterrors = '';
    this.setBegunDate(this.bridgeInspection);
    if (this.bridgeInspection.begunDate != null && this.bridgeInspection.bridgeId != null) {

      this.httpService.get<boolean>(
        'api/BridgeInspection/CheckDublicateInspections?bridgeId='
        + this.bridgeInspection.bridgeId + '&begunDate=' + moment(this.begunDate).toDate().toJSON(),
        result => {
          if (result == false) {
            this.createBridgeInspection();
          }
          else {
            confirm('Bridge Inspection Already Exists for the specified Begin Date.' +
              'Click on OK if you still wish to proceed else click on Cancel.') === true ?
              this.createBridgeInspection() : this.activeModal.close();

          }
        },
        errors => {
          console.log('errors in populate lookups');
        }
      );


    } else {
      this.hasclientErrors = true;
      this.clienterrors = 'Bridge Number or Begun Date is not in correct format or null. ';
      this.error.nativeElement.focus();
    }
  }

  createBridgeInspection() {


    this.bridgeInspection.inspectorIdAppUser = null;
    this.bridgeStoreService.addNewBridgeInspection(
      this.bridgeInspection,
      () => {

        // this.bridgeStoreService.addNewActivity2(
        //   () => {
        this.clearErrors();
        this.activeModal.close();
        this.toastService.show('New Bridge Inspection added!', {
          classname: 'bg-success text-light',
          delay: 5000
        });
        //   }
        // );

      },
      errors => {
        this.hasErrors = true;
        this.errors = errors.list();
        this.validations = errors;
        this.error.nativeElement.focus();

      }
    );
  }

  clearErrors(): void {
    this.bridgeInspection = {};
    this.begunDate = null;
    this.selectedStaff = null;
    this.hasErrors = false;
    this.errors = '';
    this.validations = new ModelStateValidations();
  }

  private setBegunDate(bridgeInspection: IBridgeInspection): void {

    if (
      moment(this.begunDate) !== undefined &&
      moment(this.begunDate) !== null &&
      moment(this.begunDate).isValid
    ) {
      bridgeInspection.begunDate = moment(this.begunDate).toDate();
    } else {
      bridgeInspection.begunDate = null;
    }
  }


}
