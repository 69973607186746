import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { SecurityService } from '../../services/security/security.service';
import { HttpService } from '../../services/http/http.service';
import * as linq from 'linq';
import { IAuthProviderConfig } from '../../model/model';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent implements OnInit, OnDestroy {
  @ViewChild('skipnav', { static: true })
  skipnav: ElementRef;
  isAuthenticated = false;
  isAdmin = false;
  isB2CUser = false;
  isBridgeInspector = false;
  isWordProcessor= false;
  isLoadRatingReviewer = false;
  isBridgeManagementSystemEngineer = false;
  isDistrictBridgeInspector = false;
  isDistrictStructuresFacilitiesEngineer = false;
  isDocumentManager = false;
  userName = '';
  userId = '';
  role = 'user';
  canImpersonate = false;
  allowB2C = false;
  isB2CAuthenticated = false;
  collapsed = true;

  constructor(
    private securityService: SecurityService,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.httpService.get<IAuthProviderConfig>(
      'api/security/getAuthProviderConfig',
      result => {
        this.allowB2C = result.allowB2C;
        this.canImpersonate = result.allowImpersonation;
      }
    );
  
    this.securityService.safeSubscribe(
      this,
      token => {
        if (token == null) {
          this.isAuthenticated = false;
          this.isAdmin = false;
          this.isB2CUser = false;
          this.userId = '';
          this.userName = '';
          this.role = 'NotAuthorized';
          this.isB2CAuthenticated = false;
        } else {
          this.isAuthenticated = true;
          this.isAdmin =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'Admin');
            this.isBridgeInspector =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'BridgeInspector');
            this.isWordProcessor =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'WordProcessor');
            this.isLoadRatingReviewer =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'LoadRatingReviewer');
            this.isBridgeManagementSystemEngineer =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'BridgeManagementSystemEngineer');
            this.isDistrictBridgeInspector =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'DistrictBridgeInspector');
            this.isDistrictStructuresFacilitiesEngineer =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'DistrictStructuresFacilitiesEngineer');
            this.isDocumentManager =
            token.roles !== undefined &&
            token.roles !== null &&
            Array.isArray(token.roles) &&
            linq.from(token.roles).any(x => x === 'DocumentManager');
            
          
        this.role = this.isAdmin === true ? 'Admin' : 
                    this.isBridgeInspector === true ? 'BridgeInspector' : 
                    this.isWordProcessor === true ? 'WordProcessor' : 
                    this.isLoadRatingReviewer === true ? 'LoadRatingReviewer' : 
                    this.isBridgeManagementSystemEngineer === true ? 'BridgeManagementSystemEngineer' : 
                    this.isDistrictBridgeInspector === true ? 'DistrictBridgeInspector' : 
                    this.isDistrictStructuresFacilitiesEngineer === true ? 'DistrictStructuresFacilitiesEngineer' : 
                    this.isDocumentManager === true ? 'DocumentManager' : 'NotAuthorized' ;
                     
          this.userId = token.userId;
          this.userName = token.fullName;
          this.isB2CAuthenticated = token.authMode === 'B2C';
        }
      },
      () => {
        this.securityService.getToken();
      }
    );
  }

  ngOnDestroy(): void {}

  skipNavigation(): void {
    this.skipnav.nativeElement.focus();
  }

  notAuthorized(): void {
    this.httpService.get('api/security/notAuthorized', () => {});
  }

  changeRole(role: string): void {
 
    location.replace('/security/impersonate/' + role);
  }
}
