<div class="modal-header bg-light">
  <h4 class="modal-title" id="inspectionRecord">
    {{header}}
  </h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Cancel" title="Cancel"
    (click)="closeModalPopup()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row" *ngIf="pullDataFor == 2">
    <div class="col d-sm-none">
      <button type="button" class="btn btn-primary" (click)="setBridgeInspection()" data-toggle="modal"
        data-target="#content2" aria-label="New Inspection" title="New Inspection">
        <span class="fas fa-plus"></span>&nbsp;New Bridge Inspection
      </button>
    </div>
    <div class="col text-right d-none d-md-block">
      <button type="button" class="btn btn-primary" (click)="setBridgeInspection()" data-toggle="modal"
        data-target="#content2" aria-label="New Inspection" title="New Inspection">
        <span class="fas fa-plus"></span>&nbsp;New Bridge Inspection
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div *ngIf="data && data.sourceData && data.sourceData.length > 0">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col small">
                Page {{ data.currentPage }} of {{ data.totalPages }}
              </div>
              <div class="col">
                <div class="text-right">
                  <button class="btn btn-secondary btn-sm" title="previous page" [disabled]="data.currentPage == 1"
                    (click)="page('previous')">
                    <span class="fas fa-angle-left"></span></button>&nbsp;&nbsp;
                  <button class="btn btn-secondary btn-sm" title="next page"
                    [disabled]="data.currentPage == data.totalPages" (click)="page('next')">
                    <span class="fas fa-angle-right"></span></button>&nbsp;&nbsp;

                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col table-responsive">
            <table class="table table-hover table-sm" aria-label="Inspections" title="Inspections"
              summary="Edit or remove inspection">
              <thead class="thead-dark">
                <tr>
                  <th style="width: 100px; text-align: center;">
                    <app-sort-button [sortData]="data" field="bridgeInspection.bridgeId" label="Bridge No" (sort)="sort($event)">
                    </app-sort-button>
                  </th>
                  <th style="text-align: center;">
                    <app-sort-button [sortData]="data" field="bridgeInspection.begunDate" label="Begin Date" (sort)="sort($event)">
                    </app-sort-button>
                  </th>
              
                  <th style="text-align: center;">
                    <app-sort-button [sortData]="data" field="begunDate" label="Received" (sort)="sort($event)">
                    </app-sort-button>
                  </th>
                  <th style="text-align: center;">
                    <app-sort-button [sortData]="data" field="inspectionType" label="Inspection Type" (sort)="sort($event)">
                    </app-sort-button>
                  </th>             
                  <th style="text-align: center;">
                    <app-sort-button [sortData]="data" field="flowDays" label="Flow Days" (sort)="sort($event)">
                    </app-sort-button>
                  </th>
                  <th style="text-align: center;">
                    <app-sort-button [sortData]="data" field="remainingDays" label="Remaining Days"
                      (sort)="sort($event)"></app-sort-button>
                  </th>
                  <th style="text-align: center;">
                    <app-sort-button [sortData]="data" field="cummilativeDays" label="Cumulative Days"
                      (sort)="sort($event)"></app-sort-button>
                  </th>
                  <th style="text-align: center;">
                    <app-sort-button [sortData]="data" field="lastUpdatedAppUser.name" label="Inspector" (sort)="sort($event)">
                    </app-sort-button>
                  </th>
                </tr>
                <tr>

                  <th>
                    <app-filter-field field="bridgeInspection.bridgeId" label="Bridge No"
                      (filter)="filter($event)" type="text">
                    </app-filter-field>
                  </th>
                  <th>
                    <app-filter-field field="bridgeInspection.begunDate" label="Begin Date" (filter)="filter($event)" type="text">
                    </app-filter-field>
                  </th>
                 
                  <th>
                    <app-filter-field field="begunDate" label="Received" (filter)="filter($event)" type="text">
                    </app-filter-field>
                  </th>
                  <th>                  
                    <app-filter-field
                    field="inspectionType"
                    label="Inspection Type"
                    (filter)="filter($event)"
                    type="select"
                    [selectValues]="[
                      { label: 'Accident', value: inspectionType.Accident },
                      { label: 'Interim', value: inspectionType.Interim },
                      { label: 'Other', value: inspectionType.Other },
                      { label: 'Routine', value: inspectionType.Routine }
                    ]"
                  ></app-filter-field>
                  </th>
                  <th>
                    <app-filter-field field="flowDays" label="Flow Days" (filter)="filter($event)" type="text">
                    </app-filter-field>
                  </th>
                  <th>
                    <app-filter-field field="remainingDays" label="Remaining Days" (filter)="filter($event)"
                      type="text"></app-filter-field>
                  </th>
                  <th>
                    <app-filter-field field="cummilativeDays" label="Cummilative Days" (filter)="filter($event)"
                      type="text"></app-filter-field>
                  </th>
                  <th>
                    <app-filter-field field="lastUpdatedAppUser.name" label="InspectorId" (filter)="filter($event)" type="text">
                    </app-filter-field>
                  </th>
                </tr>
              </thead>
              <tbody *ngFor="let bridge of data.pageData">
                
                <tr (click)="bridgeSelected(bridge)" [ngStyle]="{'background-color': bridge.returned == true ? 'lightsteelblue' : 'white' }" 
                 title='Inspection Record {{bridge.bridgeInspection.bridgeId}}'  >                 
                  <td style="white-space: nowrap; text-align: center;" >
                    <button type="button" 
                    style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                     Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 
                     sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    text-align: center;
                    padding-top: 0px;"
                    class="btn btn-sm btn-link" (click)="bridgeSelected(bridge)"
                    title="Bridge Number : {{ bridge.bridgeInspection.bridgeId  }} 
                    Begin Date     : {{bridge.bridgeInspection.begunDate | date: 'MM-dd-yyyy' &nbsp;}}
                    Received Date  : {{bridge.begunDate | date: 'MM-dd-yyyy'}}
                    Flow Days      : {{ calculatecumilative(bridge)}}
                    Remaining Days :{{ calculateRemainingDays(bridge) }}
                    Cumulative Days : {{calculatecumilative(bridge) }}
                    Inspector : {{ bridge.bridgeInspection.inspectorIdAppUser.name }}                    
                    "                    
                    > 
                    {{bridge.bridgeInspection.bridgeId}}
                    </button>
                  </td>
                  <td style="white-space: nowrap; text-align: center;">
                    {{ bridge.bridgeInspection.begunDate | date: 'MM-dd-yyyy'}}
                  </td>
                  <td style="white-space: nowrap; text-align: center;">
                    {{ bridge.begunDate | date: 'MM-dd-yyyy' }}
                  </td>
                  <td style="white-space: nowrap; text-align: center;">
                    {{   bridge.inspectionType == inspectionType.Accident ? 'Accident'
                    : bridge.inspectionType == inspectionType.Interim
                    ? 'Interim'
                    : bridge.inspectionType == inspectionType.Other                      
                    ? 'Other' 
                    : bridge.inspectionType == inspectionType.Routine ? 'Routine' : '' }}
                  </td>
                  <td style="white-space: nowrap; text-align: center;">
                    {{ calculateFlow(bridge)}}
                  </td>
                  <td style="white-space: nowrap; text-align: center;">
                    {{calculateRemainingDays(bridge)}}
                    <!-- {{ bridge.activityNumber != 2  ? calculatecumilative(bridge) - 60 : 'N/A'}} -->
                  </td>
                  <td style="white-space: nowrap; text-align: center;">
                    {{ 
                      calculatecumilative(bridge)
                    }}
                  </td>
                  <td style="white-space: nowrap; text-align: center;">
                    {{ bridge.bridgeInspection.inspectorIdAppUser.name }}
                  </td>
                </tr>
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="!data || !data.sourceData || data.sourceData.length === 0">
        There are no inspections to display
      </div>
    </div>
  </div>
  <div class="row text-danger" role="alert" *ngIf="hasErrors" >
    <div class="col">
      <strong>Errors:</strong>
      <div [innerHTML]="errors" tabindex="0" #error></div>
    </div>
  </div> 
  <div class="container-fluid" *ngIf="pullDataFor == 2 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">

      <div class="card-header">
        <div style="text-align: center;">Create Inspection Record</div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="inspectionType">Inspection Type</label> <span style="color:red;"> * </span>
              <select id="inspectionType" class="form-control form-control-sm" [(ngModel)]="inspection.inspectionType"
                aria-label=" Please select the Inspection Type" title="Please select the Inspection Type">
                <option value=""></option>
                <option value="{{inspectionType.Accident}}">Accident</option>
                <option value="{{inspectionType.Interim}}">Interim</option>
                <option value="{{inspectionType.Other}}">Other</option>
                <option value="{{inspectionType.Routine}}">Routine</option>
              </select>
            </div>
            <!-- <div class="row">
              <div class="col">
                <app-field-validation-message
                  property="InspectionType"
                  [validations]="validations"
                ></app-field-validation-message>
              </div>
            </div> -->
          </div>

          <div class="col">
            <div class="form-group">
              <label class="form-label" for="dueDate1">Due Date</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="dueDate1" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="dueDate" ngbDatepicker #a="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="a.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>                  
                </div>
              </div>              
            </div>
            
            <!-- <div class="row">
              <div class="col">
                <app-field-validation-message
                  property="DueDate"
                  [validations]="validations"
                ></app-field-validation-message>
              </div>
            </div> -->
          </div>      
        </div>

        <div class="row">          
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="fieldInspectionCompletedfield">Field Inspection Completed</label> <span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="fieldInspectionCompletedfield" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="fieldInspectionCompleted" ngbDatepicker
                  #e="ngbDatepicker" style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="e.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col">
                <app-field-validation-message
                  property="fieldInspectionCompleted"
                  [validations]="validations"
                ></app-field-validation-message>
              </div>
            </div> -->
          </div>
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="toWordProcessor">To Word Processor</label> <span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="toWordProcessor" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="toWordProcessor" ngbDatepicker #f="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="f.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col">
                <app-field-validation-message
                  property="toWordProcessor"
                  [validations]="validations"
                ></app-field-validation-message>
              </div>
            </div> -->
          </div>
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="begunDate">Received Date</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="begunDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="rd" [(ngModel)]="receivedDate" ngbDatepicker #rd="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="rd.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>                  
                </div>
              </div>              
            </div>          
         
          </div>
        </div>
        <div class="row">        

          <div class="col">
            <div class="form-group form-check">
              <input ngbAutofocus type="checkbox" class="form-check-input" id="bridgeComparisonProfile"
                [(ngModel)]="inspection.bridgeComparisonProfile"
                aria-label="Check if Bridge Comparison Profile is required"
                title="Check if Bridge Comparison Profile is required" />
              <label class="form-check-label" for="bridgeComparisonProfile">Bridge Comparison Profile Required</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check">
              <input ngbAutofocus type="checkbox" class="form-check-input" id="bridgePhotos"
                [(ngModel)]="inspection.bridgePhotos" aria-label="Check if Bridge Photos are available"
                title="Check if Bridge Photos are available" />
              <label class="form-check-label" for="bridgePhotos">Bridge Photos</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check">
              <input ngbAutofocus type="checkbox" class="form-check-input" id="workOrdersRequired"
                [(ngModel)]="inspection.workOrdersRequired" aria-label="Check if Work Orders Required"
                title="Check if Work Orders Required" />
              <label class="form-check-label" for="workOrdersRequired">Work Orders Required</label>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col">
            <div class="form-group form-check">
              <input ngbAutofocus type="checkbox" class="form-check-input" id="underWaterInspection"
                [(ngModel)]="inspection.underWaterInspectionRequired"
                aria-label="Check if Under Water Inspection is required"
                title="Check if Under Water Inspection is required" />
              <label class="form-check-label" for="underWaterInspection">Under Water Inspection Required</label>
            </div>
            <div class="form-group" *ngIf="(inspection.underWaterInspectionRequired == true)">
              <label class="form-label" for="underwaterInspection">Underwater Inspection Date</label> <span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="underwaterInspection" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="waterInspectionDate" ngbDatepicker #d="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col">
                <app-field-validation-message
                  property="UnderWaterInspectionDate"
                  [validations]="validations"
                ></app-field-validation-message>
              </div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="inspectorReviewComments">Comments</label>
              <textarea class="form-control" id="inspectorReviewComments" rows="3"
                [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>     
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()"  aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="pullDataFor == 3 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">Word Processing Systems Operator</div>
        
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
      </div>
      <div class="card-body">
        <div class="row" style="background-color: lightsteelblue;">{{inspection.returned == true ? 'Note: BMSE has sent the inspection record back for FARC':""}}</div>   
    
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="dueDate">To Bridge Inspector</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="dueDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="toBridgeInspector" ngbDatepicker #g="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="operatorComments">Comments</label>
              <textarea class="form-control" id="operatorComments" rows="3"
                [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div> 
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <div *ngIf="this.returned != true">
          <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
            (click)="saveActivity()">
            <span class="fas fa-save"></span>&nbsp;Save
          </button>
        </div>
        <div *ngIf="this.returned == true">
          <button type="button" class="btn btn-primary btn-sm"
            aria-label="Send Back to Bridge Management Systems Engineering Review"
            title="Send Back to Bridge Management Systems Engineering Review" (click)="returnActivity()">
            <span class="fas fa-share"></span>&nbsp;Send Back
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid" *ngIf="pullDataFor == 4 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">Inspector Final Review </div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
  
      </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="toDBIA">To DBIA</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="toDBIA" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="toDistrictBridgeInspectionAdmin" ngbDatepicker #g="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="inspectorReviewComments">Comments</label>
              <textarea class="form-control" id="inspectorReviewComments" rows="3"
                [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="pullDataFor == 5 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">District Bridge Inspection Administrator Initial Review</div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="initialReviewCompletedDate">Complete</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="initialReviewCompletedDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="initialReviewCompletedDate" ngbDatepicker
                  #g="ngbDatepicker" style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="DBIAComments">Comments</label>
              <textarea class="form-control" id="DBIAComments" rows="3" [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="pullDataFor == 6 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">Load Rating Review</div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="loadRatingPendingDate">Pending</label>
              <div class="input-group input-group-sm">
                <input id="loadRatingPendingDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="loadRatingPendingDate" ngbDatepicker
                  #loadRatingPending="ngbDatepicker" style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="loadRatingPending.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="LoadRatingReviewcomplete">Complete</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="LoadRatingReviewcomplete" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="LoadRatingReviewcomplete" [(ngModel)]="loadRatingCompletedDate"
                  ngbDatepicker #g="ngbDatepicker" style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="DBIAComments">Comments</label>
              <textarea class="form-control" id="DBIAComments" rows="3" [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>


    </div>
  </div>

  <div class="container-fluid" *ngIf="pullDataFor == 7 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">Bridge Management Systems Engineering Review </div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="transferTo">Send To</label><span style="color:red;"> * </span>
              <select id="transferTo" class="form-control form-control-sm" aria-label="Transfer to" title="Transfer to"
                [(ngModel)]="inspection.bridgeManagementEngineerTransferTo">
                <option></option>
                <option value="1">WPO for Feasibility Action Review</option>
                <option value="2">DBIA</option>
                <option value="3">Documents Manager</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="bmserDate">Send Date</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="bmserDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="bmserDate" [(ngModel)]="bridgeManagementEngineerTransferDate"
                  ngbDatepicker #g="ngbDatepicker" style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="bmserComments">Comments</label>
              <textarea class="form-control" id="bmserComments" rows="3" [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="pullDataFor == 8 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">District Bridge Inspection Administrator Final Review </div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="finalReviewDate">Final Review</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="finalReviewDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="finalReview" ngbDatepicker #g="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="DBIAComments">Comments</label>
              <textarea class="form-control" id="DBIAComments" rows="3" [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="pullDataFor == 9 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">District Structures & Facilities Engineer Review </div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
    </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="dsferDate">Review Date</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="dsferDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="facilitiesEngineerReview" ngbDatepicker
                  #g="ngbDatepicker" style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="DBIAComments">Comments</label>
              <textarea class="form-control" id="DBIAComments" rows="3" [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="pullDataFor == 10 && isBridgeSelected == true" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-header">
        <div style="text-align: center;">Report Filing by Documents Manager </div>
        <div class="row" >
          <div class="col-xs-2">
          <label for="bridgeId" class="col-xs-6 col-form-label">Bridge # </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="bridgeId" aria-label="Bridge Number" title="Bridge Number {{inspection.bridgeInspection.bridgeId}}"
              [ngModel]="inspection.bridgeInspection.bridgeId">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="BegunDate" class="col-xs-6 col-form-label">Begin Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="BegunDate" aria-label="Inspection Begin Date" title="Inspection Begin Date {{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.bridgeInspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="receivedDate" class="col-xs-6 col-form-label">Received Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="receivedDate" aria-label="Inspection received Date" title="Inspection received Date {{inspection.begunDate | date: 'MM-dd-yyyy' }}"
              value="{{inspection.begunDate | date: 'MM-dd-yyyy' }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="FlowDate" class="col-xs-6 col-form-label">Flow Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="FlowDate" aria-label="Inspection Flow Date" title="Inspection Flow Date {{calculateFlow(inspection) }}"
              value="{{calculateFlow(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="RemainingDate" class="col-xs-6 col-form-label">Remaining Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="RemainingDate" aria-label="Inspection Remaining Date" title="Inspection Remaining Date {{calculateRemainingDays(inspection) }}"
              value="{{calculateRemainingDays(inspection) }}">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="CumulativeDate" class="col-xs-6 col-form-label">Cumulative Date: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="CumulativeDate" aria-label="Inspection Cumulative Date" title="Inspection Cumulative Date {{calculatecumilative(inspection) }}"
              value="{{calculatecumilative(inspection) }}">
          </div>
        </div>
          <div class="col-xs-2">
          <label for="Inspector" class="col-xs-6 col-form-label">Inspector</label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="Inspector" aria-label="Inspector Name" title="Inspector Name: {{inspection.lastUpdatedAppUser.name}}"
              [ngModel]="inspection.lastUpdatedAppUser.name">
          </div>
        </div>
        <div class="col-xs-2">
          <label for="inspectionType" class="col-xs-6 col-form-label">Inspection Type: </label>
          <div class="col-xs-6">
            <input type="text" readonly class="form-control-plaintext" id="inspectionType" aria-label="Inspection received Date" title="Inspection Type"
              value="{{   inspection.inspectionType == inspectionType.Accident ? 'Accident'
              : inspection.inspectionType == inspectionType.Interim
              ? 'Interim'
              : inspection.inspectionType == inspectionType.Other                      
              ? 'Other' 
              : inspection.inspectionType == inspectionType.Routine ? 'Routine' : '' }}">
          </div>
        </div>
      </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-label" for="documentsManagerFillingDate">Filing Date</label><span style="color:red;"> * </span>
              <div class="input-group input-group-sm">
                <input id="documentsManagerFillingDate" class="form-control" placement="top-left" [firstDayOfWeek]="7"
                  placeholder="MM-DD-YYYY" name="dp" [(ngModel)]="filingDate" ngbDatepicker #g="ngbDatepicker"
                  style="max-width: 150px;" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="g.toggle()" type="button">
                    <span class="fas fa-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-check-label" for="DBIAComments">Comments</label>
              <textarea class="form-control" id="DBIAComments" rows="3" [(ngModel)]="inspection.comments"></textarea>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModalPopup()" aria-label="Cancel"
          title="Cancel">
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm" aria-label="Save Inspection" title="Save Inspection"
          (click)="saveActivity()">
          <span class="fas fa-save"></span>&nbsp;Save
        </button>
      </div>
    </div>
  </div>
  
</div>
