<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [ngClass]="[toast.classname, 'host']"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hide)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTemplate"></ng-template>
  </ng-template>
  <ng-template #text>{{ toast.textOrTemplate }}</ng-template>
</ngb-toast>
