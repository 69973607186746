<div class="row">
  <div class="col-sm-12 col-md-6">
    <div class="card">
      <h5 class="card-header" tabindex="0">Activities</h5>
      <div class="card-body">
        <ul class="nav flex-column">
          <li class="nav-item">
            <!-- <button type="button" class="btn btn-primary">
				Notifications <span class="badge badge-light">4</span>
        </button> -->
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(2)"
              aria-label="Create Inspection Record"
              *ngIf="isBridgeInspector || isAdmin"
            >
              Create Inspection Record
              <span class="badge badge-light">{{ activityResponse2 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(3)"
              aria-label="Word Processing Systems Operator "
              *ngIf="isWordProcessor || isAdmin"
            >
              Word Processing Systems Operator
              <span class="badge badge-light">{{ activityResponse3 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(4)"
              aria-label="Inspector Final Review"
              *ngIf="isBridgeInspector || isAdmin"
            >
              Inspector Final Review
              <span class="badge badge-light">{{ activityResponse4 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(5)"
              aria-label="District Bridge Inspection Administrator Initial Review "
              *ngIf="
                isDistrictBridgeInspectorAdmin ||
                isBridgeManagementSystemEngineer ||
                isDistrictStructuresFacilitiesEngineer ||
                isAdmin
              "
            >
              District Bridge Inspection Administrator Initial Review
              <span class="badge badge-light">{{ activityResponse5 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(6)"
              aria-label="Load Rating Review "
              *ngIf="isLoadRatingReviewer || isAdmin"
            >
              Load Rating Review
              <span class="badge badge-light">{{ activityResponse6 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(7)"
              aria-label="Bridge Management Systems Engineering Review "
              *ngIf="
                isDistrictBridgeInspectorAdmin ||
                isBridgeManagementSystemEngineer ||
                isDistrictStructuresFacilitiesEngineer ||
                isAdmin
              "
            >
              Bridge Management Systems Engineering Review
              <span class="badge badge-light">{{ activityResponse7 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(8)"
              aria-label="District Bridge Inspection Administrator Final Review "
              *ngIf="
                isDistrictBridgeInspectorAdmin ||
                isBridgeManagementSystemEngineer ||
                isDistrictStructuresFacilitiesEngineer ||
                isAdmin
              "
            >
              District Bridge Inspection Administrator Final Review
              <span class="badge badge-light">{{ activityResponse8 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(9)"
              aria-label="District Structures & Facilities Engineer Review "
              *ngIf="
                isDistrictBridgeInspectorAdmin ||
                isBridgeManagementSystemEngineer ||
                isDistrictStructuresFacilitiesEngineer ||
                isAdmin
              "
            >
              District Structures & Facilities Engineer Review
              <span class="badge badge-light">{{ activityResponse9 }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="btn btn-link"
              tabindex="0"
              (click)="getTableData(10)"
              aria-label="Report Filing by Documents Manager "
              *ngIf="isDocumentManager || isAdmin"
            >
              Report Filing by Documents Manager
              <span class="badge badge-light">{{ activityResponse10 }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-6">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <h5 class="card-header" tabindex="0">Average Review Time Reports</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <label class="form-label" for="fromDate">From Date</label
                ><span style="color: red"> * </span>
                <div class="input-group input-group-sm">
                  <input
                    id="fromDate"
                    class="form-control"
                    placement="top-left"
                    [firstDayOfWeek]="7"
                    placeholder="MM-DD-YYYY"
                    name="dp"
                    [(ngModel)]="fromDate"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    style="max-width: 150px"
                    aria-label="Select From Date for Average Review Report"
                    Title="From Date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      (click)="d.toggle()"
                      type="button"
                      aria-label="Choose From Date"
                    >
                      <span class="fas fa-calendar"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <label class="form-label" for="toDate">To Date</label
                ><span style="color: red"> * </span>
                <div class="input-group input-group-sm">
                  <input
                    id="toDate"
                    class="form-control"
                    placement="top-left"
                    [firstDayOfWeek]="7"
                    placeholder="MM-DD-YYYY"
                    name="dp"
                    [(ngModel)]="toDate"
                    ngbDatepicker
                    #t="ngbDatepicker"
                    style="max-width: 150px"
                    aria-label="Select To Date for Average Review Report"
                    Title="To Date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      (click)="t.toggle()"
                      type="button"
                      aria-label="Choose To Date"
                    >
                      <span class="fas fa-calendar"></span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-5">
                <label class="form-label" >Activity</label
                ><span style="color: red"> * </span>
                <select2 [data]="dropdownList"
                  [placeholder]="'Select Activity'"
                   aria-label="Select Activity"
                   [(ngModel)]="SelectedActivities"
                   [options]="options">
                </select2>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <br />
                <button
                  class="btn btn-primary"
                  aria-label="Click to Generate Average Review Time Report"
                  (click)="getAverageReviewTimeReport()"
                >
                  <span class="fas fa-file-pdf"></span>&nbsp; Generate Report
                </button>
              </div>
            </div>
            <div
              class="row text-danger"
              role="alert"
              *ngIf="bridgeAverageReportErrors"
            >
              <div class="col">
                <strong>Errors:</strong>
                <div [innerHTML]="bridgeAverageReportErrors"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <h5 class="card-header" tabindex="0">Bridge Record Query</h5>
          <div class="card-body">
            <div class="form-group mx-sm-3 mb-2">
              <div class="row">
                <div class="col">
                  <input
                    type="number"
                    class="form-control"
                    id="bridgeSearchId"
                    [(ngModel)]="bridgeSearch"
                    placeholder="Bridge Number (6 digit)"
                    aria-label="Enter 6 digit bridge number"
                  />
                </div>
                <span style="color: red"> * </span>
                <div class="col">
                  <button
                    class="btn btn-primary"
                    aria-label="Search Bridges"
                    (click)="searchBridges(bridgeSearch)"
                  >
                    <span class="fas fa-search"></span>&nbsp; Search
                  </button>
                </div>
              </div>
              <br />
              <div class="row" *ngIf="bridgeSearchResults.length != 0">
                <table
                  class="table table-hover table-sm"
                  aria-label="Inspections for searched bridge ID"
                  title="Inspections for searched bridge ID"
                  summary="Displaying all Inspections for the Bridge {{
                    bridgeSearch
                  }} "
                >
                  <thead class="thead-dark">
                    <!-- <tr>

              <th style="width: 100px; text-align: center;">
                <app-sort-button
                  [sortData]="data"
                  field="inspectionBegunDate"
                  label="Begun Date"
                  (sort)="sort($event)"
                ></app-sort-button>
              </th>
              <th style="text-align: center;">
                <app-sort-button
                  [sortData]="data"
                  field="filingDate"
                  label="Completed Date"
                  (sort)="sort($event)"
                ></app-sort-button>
              </th>
              <th style="text-align: center;">
                <app-sort-button
                  [sortData]="data"
                  field="activityId"
                  label="Activity"
                  (sort)="sort($event)"
                ></app-sort-button>
              </th>
              <th style="text-align: center;">
                <app-sort-button
                  [sortData]="data"
                  field="inspectionType"
                  label="Inspection Type"
                  (sort)="sort($event)"
                ></app-sort-button>
              </th>
            </tr>  -->

                    <tr>
                      <th
                        style="width: 100px; text-align: center"
                        title="Begun Date"
                        aria-label="Begun Date"
                        tabindex="0"
                      >
                        Begun Date
                      </th>
                      <th
                        style="text-align: center"
                        title="Completed Date"
                        aria-label="Completed Date"
                        tabindex="0"
                      >
                        Completed Date
                      </th>
                      <th
                        style="text-align: center"
                        title="Activity Date"
                        aria-label="Activity Date"
                        tabindex="0"
                      >
                        Activity
                      </th>
                      <th
                        style="text-align: center"
                        title="Inspection Type"
                        aria-label="Inspection Type"
                        tabindex="0"
                      >
                        Inspection Type
                      </th>
                      <th
                        style="text-align: center"
                        title="Delete Bridge"
                        aria-label="Delete Bridge"
                        *ngIf="
                          isBridgeInspector ||
                          isAdmin ||
                          isDistrictBridgeInspectorAdmin
                        "
                        tabindex="0"
                      >
                        Delete Bridge
                      </th>
                    </tr>
                    <!-- <tr>
                      <th>
                        <app-filter-field field="inspectionBegunDate" label="Begun Date" (filter)="filter($event)"
                          type="text"></app-filter-field>
                      </th>
                      <th>
                        <app-filter-field field="filingDate" label="Completed Date" (filter)="filter($event)"
                          type="text"></app-filter-field>
                      </th>
                      <th>
                        <app-filter-field field="activityId" label="Activity Id" (filter)="filter($event)" type="text">
                        </app-filter-field>
                      </th>
                      <th>
                        <app-filter-field field="inspectionType" label="Inspection Type" (filter)="filter($event)"
                          type="select" [selectValues]="[
                      { label: 'Accident', value: inspectionType.Accident },
                      { label: 'Interim', value: inspectionType.Interim },
                      { label: 'Other', value: inspectionType.Other },
                      { label: 'Routine', value: inspectionType.Routine }

                    ]"></app-filter-field>
                      </th>


                      <th *ngIf="isBridgeInspector || isAdmin || isDistrictBridgeInspectorAdmin"></th>
                    </tr> -->
                  </thead>

                  <!-- <thead class="thead-dark">
              <tr>
                <th style="width: 100px; text-align: center;">
                 Begun Date

                </th>
                <th style="text-align: center;">
                  Completed Date
                </th>
                <th style="text-align: center;">
                  Activity
                </th>
                <th style="text-align: center;">
                 Inspection Type
                </th>
                <th style="text-align: center;" *ngIf="isBridgeInspector || isAdmin || isDistrictBridgeInspectorAdmin">
                  Delete Bridge
                 </th>

              </tr>
            </thead> -->
                  <tbody *ngFor="let bridgeSearchResult of bridgeSearchResults">
                    <tr>
                      <td
                        style="text-align: center; vertical-align: middle"
                        tabindex="0"
                        (click)="
                          getSelectedBridgeInfo(
                            bridgeSearchResult.bridgeInspectionId
                          )
                        "
                      >
                        {{
                          bridgeSearchResult.inspectionBegunDate
                            | date: 'MM-dd-yyyy'
                        }}
                      </td>
                      <td
                        style="white-space: nowrap; text-align: center"
                        tabindex="0"
                        (click)="
                          getSelectedBridgeInfo(
                            bridgeSearchResult.bridgeInspectionId
                          )
                        "
                      >
                        {{ bridgeSearchResult.filingDate | date: 'MM-dd-yyyy' }}
                      </td>
                      <td
                        style="white-space: nowrap; text-align: center"
                        tabindex="0"
                      >
                        {{ bridgeSearchResult.activityId }}
                      </td>
                      <td
                        style="white-space: nowrap; text-align: center"
                        tabindex="0"
                        (click)="
                          getSelectedBridgeInfo(
                            bridgeSearchResult.bridgeInspectionId
                          )
                        "
                      >
                        {{
                          bridgeSearchResult.inspectionType ==
                          inspectionType.Accident
                            ? 'Accident'
                            : bridgeSearchResult.inspectionType ==
                              inspectionType.Interim
                            ? 'Interim'
                            : bridgeSearchResult.inspectionType ==
                              inspectionType.Other
                            ? 'Other'
                            : 'Routine'
                        }}
                      </td>
                      <td
                        style="white-space: nowrap; text-align: center"
                        *ngIf="
                          isBridgeInspector ||
                          isAdmin ||
                          isDistrictBridgeInspectorAdmin
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-danger"
                          (click)="
                            removeBridgeInspection(
                              deleteModal,
                              bridgeSearchResult
                            )
                          "
                          aria-label="Delete Bridge Inspection"
                          title="Delete Bridge Inspection"
                        >
                          <span class="fas fa-trash-alt"></span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <label for="bridgeID" class="sr-only">Bridge</label>
              <div
                class="row text-danger"
                role="alert"
                *ngIf="bridgeQueryErrors"
              >
                <div class="col">
                  <strong>Errors:</strong>
                  <div [innerHTML]="bridgeQueryErrors"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-c="close" let-d="dismiss"></ng-template>

<!-- <div class="container-fluid"><button pp-table></button pp-table></div> -->

<!-- <ng-template #content let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">Profile update + {{this.pullData}}</h4>
	  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	  </button>
	</div>
	<div class="modal-body">


	</div>
	<div class="modal-footer">
	  <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
	</div>
  </ng-template> -->

<!-- <button class="btn btn-lg btn-outline-primary" (click)="getTableData(2)">Launch demo modal</button> -->

<!-- <hr>

  <pre>{{closeResult}}</pre> -->
<ng-template #BridgeInspectionRemovedToast>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z"
    />
  </svg>
  Inspection removed
</ng-template>
<ng-template #deleteModal let-modal>
  <div class="modal-header text-white bg-danger">
    <h4 class="modal-title" id="deleteSampleModalLabel">
      Delete {{ removebridgeInspection }} Inspection ?
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Cancel delete"
      title="Cancel delete"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-danger">
      <strong>WARNING:</strong>&nbsp;This action is permanent. Are you sure you
      want to delete the {{ removebridgeInspection }} Bridge Inspection ?
    </p>
  </div>
  <div class="modal-footer" style="display: block">
    <div class="row">
      <div class="col">
        <input
          ngbAutofocus
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="checkUserId"
          placeholder="Enter your User ID"
          aria-placeholder="Enter your User ID"
          aria-label="Enter your user ID to enable delete option"
          title="Enter your user ID to enable delete option"
        />
      </div>
      <div class="col text-right">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          data-dismiss="modal"
          (click)="modal.dismiss()"
          aria-label="Cancel delete"
          title="Cancel delete"
        >
          <span class="fas fa-ban"></span>&nbsp;Cancel
        </button>
        &nbsp;
        <button
          type="button"
          class="btn btn-danger btn-sm"
          (click)="modal.close()"
          aria-label="Delete"
          title="Delete"
          [disabled]="
            checkUserId != undefined &&
            userId != undefined &&
            checkUserId != null &&
            userId != null &&
            checkUserId.trim().toUpperCase() != userId.trim().toUpperCase()
          "
        >
          <span class="fas fa-trash-alt"></span>&nbsp;Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>
